<template>
	<div class="row align-items-stretch flex-column flex-lg-row">
		<div v-for="col in cols" :key="col" class="col my-3 mx-0 mx-lg-3" v-if="showColumn(col)">
            <!-- History -->
            <div v-if="col === 'history-left'" class="bg-fade-section box-shadow-section text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
                <!-- <p>Check your Code History to see what you've entered so far.</p> -->
                <router-link :to="{ name:'code-history' }" class="text-decoration-none d-block">
                    <img src="../assets/images/trees.png" class="img-fluid mb-4 blurb-img position-relative" alt="Check your Code History to see what you've entered so far.">
                    <h1 class="h3 font-weight-normal font-italic text-light position-relative">CODE HISTORY</h1>
                </router-link>
            </div>

            <!-- Winners -->
            <div v-if="col === 'winners' && hasWinners">
                <h1 class="h3 roboto-900-italic">THE WINNERS</h1>
				<p>Take a look at the current winners!</p>
                <p><router-link :to="{ path: '/winners' }"><img src="../assets/images/arrow-circle-right-solid.png" class="img-fluid w-10" alt="THE WINNERS"></router-link></p>
			</div>

			<!-- FAQ -->
            <div v-if="col === 'faq'" class="bg-fade-section box-shadow-section text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
				<!-- <p>Check our FAQ page for answers.</p> -->
                <router-link :to="{ name:'faq' }" class="text-decoration-none d-block">
                    <img src="../assets/images/car.png" class="img-fluid mb-4 blurb-img position-relative" alt="Check our FAQ page for answers.">
                    <h1 class="h3 font-weight-normal font-italic text-light position-relative">HAVE QUESTIONS?</h1>
                </router-link>
			</div>

            <!-- RULES -->
            <div v-if="col === 'rules'" class="bg-fade-section box-shadow-section text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
                <!-- <p>View official rules on the Rules page.</p> -->
                <router-link :to="{ name:'rules' }" class="text-decoration-none d-block">
                    <img src="../assets/images/tent.png" class="img-fluid mb-4 blurb-img position-relative" alt="View official rules on the Rules page.">
                    <h1 class="h3 font-weight-normal font-italic text-light position-relative">OFFICIAL RULES</h1>
                </router-link>
            </div>
            
            <!-- Play Game -->
            <div v-if="col === 'play-game'">
                <h1 class="h3 roboto-900-italic">PLAY THE GAME</h1>
                <p>Log in and submit your codes.</p>
                <p><router-link :to="{ name:'enter-code' }"><img src="../assets/images/arrow-circle-right-solid.png" class="img-fluid w-10" alt="PLAY THE GAME"></router-link></p>
            </div>
            
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			cols: {
				type: Array,
				validation(value) {
					return value.length == 2;
				},
			},
		},
        computed: {
		    hasWinners(){
                let pages = this.$store.get('auth/sessionData@pages');
                if (!pages)
                    return false;
                let found = false;
                pages.forEach(value=>{
                    if (value.name === 'winners'){
                        found=true;
                    }
                })
                return found;
            },
            associate() {
                return /employee|associate/i.test(this.$store.get('auth/currentUserType'));
            }
        },
        methods:  {
            showColumn(col) {
                if (col==='winners' && !this.hasWinners){
                    return false;
                }
                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
//.bg-balls {
//    background-image: url(../assets/images/balls.png) ;
//    background-repeat:no-repeat;
//    background-size: auto;
//    background-position: 90% 50%;
//}
.position-relative {
    position: relative;
    top: -70px;
    @media(min-width: $lg) {
        top: -100px;
    }
    .enter-code-route & {
        top: -70px;
    }
}
p {
    &.position-relative {
        .enter-code-route & {
            top: -40px;
        }
    }
}
</style>
