<template>
    <div id="app" :data-user="this.$store.get('auth/currentUserEmail')" :class="bodyClasses" v-show="readyToShowView">
        <!-- <header class="header" v-ada="['modal-non-interactive']" v-if="!isInMatch || showBanner"> -->
        <header class="header" v-ada="['modal-non-interactive']">

            <!--
                Lets hold off on this unless they ask. Skips links are needed as long as all content is contained in the proper elements like we're doing.
                <div class="accessibility-header position-absolute">
                <button class="btn btn-nav btn-primary sr-only sr-only-focusable route-focus" type="button" @click="adaFocus($event, true)" data-target="#navigation" v-ada>Skip to Navigation
                </button>
                <button class="btn btn-nav btn-primary sr-only sr-only-focusable" type="button" @click="adaFocus($event)" data-target="#main" v-ada>Skip to Main Content</button>
                <button class="btn btn-nav btn-primary sr-only sr-only-focusable" type="button" @click="adaFocus($event)" data-target="#footer" v-ada>Skip to Footer</button>
            </div> -->

            <div class="header-top d-flex align-items-center">
                <div class="header-top-inner container-fluid d-flex align-items-center justify-content-between">
                    <div class="text-center text-md-left" :class="isPortrait ? 'w-30' : ''">
                        <router-link class="top-logo" :to="{name:'home'}">
                            <img  src="./assets/images/savemart.png" alt="Go to Save Mart" class="img-fluid mr-md-4">
                            <img  src="./assets/images/lucky.png" alt="Go to Lucky Supermarkets" class="img-fluid">
                        </router-link>
                        <!-- <router-link class="top-logo" :to="{name:'home'}">
                            <img  src="./assets/images/lucky.png" alt="Go to Lucky Supermarkets" class="img-fluid">
                        </router-link> -->
                    </div>

                    <router-link class="btn btn-lightgreen btn-rounded color-light mr-2 py-2 px-3 px-lg-4 menu-section-item" :to="{name:'enter-code'}" v-if="showEnterCodeButton && !isHome">Enter Code</router-link>
                    <router-link class="btn btn-lightgreen btn-rounded color-light mr-2 py-2 px-3 px-lg-4" :to="{name:'returning-player'}" v-else-if="showPlayTheGameButton">Play the game</router-link>
                    <span v-else></span>

                    <button v-if="!isHome" class="navbar-toggler ml-2 menu-section-item"
                            type="button"
                            data-target="#main-menu"
                            aria-controls="main-menu"
                            aria-expanded="false"
                            @click="toggleMenu"
                            ref="navbarToggler">
                        <i class="icon-bar"></i>
                        <i class="icon-bar icon-bar-x"></i>
                        <i class="icon-bar icon-bar-x icon-bar-sneaky"></i>
                        <i class="icon-bar"></i>
                        <span class="sr-only">Menu</span>
                    </button>
                    <nav id="navigation" class="navbar flex-shrink-0 menu-section-item" ref="nav">
                        <div class="main-menu" id="main-menu">
                            <app-nav v-on:close="toggleMenu(false)"></app-nav>
                        </div>
                    </nav>
				</div>
            </div>
            <!-- <div class="bg-red text-light text-center font-weight-bold py-3">Original Match 3 Online Game Codes can be submitted until 11:59:59PM PST on November 15, 2023.</div> -->
        </header>

        <!-- removed modal-non-interactive to fix some bugs -->
        <main ref="main" id="main" v-ada="['menu-non-interactive']" data-anchor="main" :class="{ 'is-in-match' : isInMatch }">
            <div class="container-fluid h-100" v-if="!isInMatch">
                <div class="row pt-50 pb-50">
                    <div class="col-sm-6 mx-auto text-center">
                        <img :src="require(`./assets/images/color-prize-horizontal.png`)" alt="MONOPOLY SAVINGS ROAD TRIP" class="img-fluid" :class="!isHome ? 'w-75' : ''">
                    </div>
                </div>
            </div>
            <router-view v-if="readyToShowView" ref="view"></router-view>
            <modal-service-component></modal-service-component>
            <lang id="app-style"></lang>
            <p class="sr-only" aria-live="assertive" aria-atomic="true">{{ $store.get('ada/alert') }}</p>
        </main>

        <section ref="sponsors" v-if="!isInMatch && !showBanner && showSponsors" id="sponsors" class="sponsors py-2 px-3 px-lg-5 py-lg-3">
            <h2 class="font-size-title text-dark text-center">Brought to you by:</h2>
            <div class="d-flex justify-content-around flex-wrap">
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/mondelez.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/coca-cola.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/pepsi.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/acosta.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/fritolay.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/general.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/kraft.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/clorox.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/unilever.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/taylor.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/gp.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/hormel.png" alt="" class="img-fluid">
                </figure>
                <figure class="sponsored-by">
                    <img src="./assets/images/logos/keurig.png" alt="" class="img-fluid">
                </figure>
            </div>
        </section>

        <div class="container-fluid">
            <div class="row pt-50 pb-50 text-center bg-fade-section">
                <div class="col-12 font-size-xl roboto-900-italic text-center text-light">NOT A REWARDS MEMBER?</div>
                <div class="col-sm-12 col-lg-6 mx-auto py-4 text-center text-lg-right">
                    <a href="https://savemart.com/register" class="d-inline-block bg-white color-greenalt font-size-large text-decoration-none w-75 text-center reward" target="_blank">Sign up for Save Mart Rewards <span class="d-inline-block font-size-xl arrow">&gt;</span></a>
                </div>
                <div class="col-sm-12 col-lg-6 mx-auto py-4 text-center text-lg-left">
                    <a href="https://luckysupermarkets.com/register" class="d-inline-block bg-white color-red font-size-large text-decoration-none w-75 text-center reward" target="_blank">Sign up for Lucky Rewards <span class="d-inline-block font-size-xl arrow">&gt;</span></a>
                </div>
            </div>
        </div>

        <div ref="banner" v-if="showBanner && pickBanner" id="banner" class="banner row mx-0">
            <div class="col-sm-12 p-0 text-center">
                <a :href="pickBanner.link" target="_blank" v-if="pickBanner.link">
                    <img :src="require(`./assets/images/banners/${pickBanner.image}`)" :alt="`${pickBanner.description}`" class="img-fluid">
                </a>
                <img :src="require(`./assets/images/banners/${pickBanner.image}`)" :alt="`${pickBanner.description}`" class="img-fluid" v-else>
            </div>
        </div>

        <!-- <footer v-if="!isInMatch || showBanner" id="footer" class="footer" v-ada="['modal-non-interactive', 'menu-non-interactive']" data-anchor="footer"> -->
        <footer id="footer" class="footer" v-ada="['modal-non-interactive', 'menu-non-interactive']" data-anchor="footer">
            <div class="container text-center py-4">
                <lang id="footer-legal" class="text-white font-size-sm">
                    Copyright 2024. All Rights Reserved.
                    <a href="https://savemart.com/privacy-policy" class="font-weight-normal" target="_blank">Privacy Policy.</a>
                    Visit our official websites:
                    <a href="https://savemart.com" class="font-weight-normal" target="_blank">Save Mart Supermarkets</a> | 
                    <a href="https://luckysupermarkets.com" class="font-weight-normal" target="_blank">Lucky Supermarkets</a>
                </lang>
            </div>
        </footer>
    </div>
</template>

<script>
import "./assets/js/jquery.matchHeight.min";
import "./assets/js/smooth-scroll";
import "./assets/js/jquery.ada-navigation";

import cookie from "./utils/cookie";

import ModalServiceComponent from "./components/ModalServiceComponent";
import AppNav from "./components/AppNav";
import Countdown from "./components/countdown";

export default {
    name: "app",

    components: {
        AppNav,
        ModalServiceComponent,
        Countdown,
    },

    data() {
        return {
            // This should allow individual pages control over connectivity issues
            watchConnectivityState: false,

            hideAppPromoRefresh: false,
            backPressedQuit:     false,
            tickerStyleTag:
                                 window.navigator.userAgent.indexOf("MSIE ") > 0 && false,
            currentTime:         $.now() / 1000,

            banners: [
                //{image: "1_Diamond_Acosta_Banner_Buitoni.jpg", link: "https://buitoni.com/", description: "Quick Weeknight Winners. Try Buitoni's Alfredo Sauce and Three Cheese Tortellini. Learn about them from the Buitoni website right now!"},
                //{image: "1_Diamond_Acosta_Banner_Hostess.jpg", link: "https://www.hostesscakes.com/", description: "Live Your Mostess. Try Hostess snack cakes. Learn about them from the Hostess website right now!"},
                //{image: "1_Diamond_Acosta_Banner_Juanitas.jpg", link: "https://www.juanitas.com/", description: "Try Juanita's nacho cheese sauce. Learn about it from the Juanita's website right now!"},
                //{image: "1_Diamond_Acosta_Banner_Prego.jpg", link: "https://www.campbells.com/prego", description: "The taste they all love, all in one jar. Try Prego's Italian sauce. Learn about it from the Prego website right now!"},
                //{image: "1_Diamond_Acosta_Banner_SparklingIce.png", link: "https://www.sparklingice.com/", description: "Choose your flavor adventure. Try Sparkling Ice. Always zero sugar. Learn about it from the Sparkling Ice website right now!"},
                {image: "1_Diamond_Coke_Banner_BodyArmor.jpg", link: "https://www.drinkbodyarmor.com/", description: "Reach for real hydration. Try BodyArmor's Sportwater, Fruit Punch Zero Sugar, Peach Mango Lyte, Strawberry Banana, and Strawberry Kiwi Flash I.V. Flavors. Learn about them from the BodyArmor website right now!"},
                {image: "1_Diamond_Coke_Banner_Monster.jpg", link: "https://www.monsterenergy.com/en-us/", description: "Save on Monster Energy 4-Packs, Regular and Zero Ultra flavors. Only select items eligible. While supplies last. Shop Monster Energy now!"},
                {image: "1_Diamond_Coke_Banner_Simply.jpg", link: "https://www.coca-cola.com/us/en/brands/simply/products/orange-juices?msclkid=145ae31737531aa7953a857fe2c0fe09&utm_source=bing&utm_medium=cpc&utm_campaign=10212_USA_WPP_BN9-Simpl-Bing_OUCampaign-NAtBG00004%7CSimpleIsn%27tSimple%7C0%3A0-Brand-EX_2024-01_2024-07_OU_NA_EN_OJ&utm_term=simply%20orange&utm_content=10212_20240405165602_Search_RSA-NA_NA_CT_Bing_PA_ROS-ROT_SA360_CPC_REACH_NA_1P_MIC-NA_SimplyOJAdes_PulpFree&gclid=145ae31737531aa7953a857fe2c0fe09&gclsrc=3p.ds#simply-orange-pulp-free", description: "All natural. Simply delightful. Try Simply Orange Juice, Lemonade, and Fruit Punch. Learn more about Simply's flavors on their website now!"},
                {image: "1_Diamond_Coke_Banner_Sprite.png", link: "https://www.coca-cola.com/us/en/brands/sprite", description: "Obey 'Till the last drop. Obey your thirst. Try Sprite. Sign in to start saving!"},
                {image: "1_Diamond_Coke_Banner_TopoChico.jpg", link: "https://www.coca-cola.com/us/en/brands/topo-chico", description: "Savor every sip. Try Topo Chico's new sparkling water in Lime, Tangerine, and Blueberry flavors! Sign in to start saving!"},
                {image: "1_Diamond_Pepsi_Banner_Bubly.jpg", link: "https://www.bubly.com/#/", description: "Bursting with fruit flavor. Try Bubly sparkling water. Learn more about it on Bubly's website."},
                {image: "1_Diamond_Pepsi_Banner_Gatorade.jpg", link: "https://www.gatorade.com/?utm_source=bing&utm_medium=paidsearch&utm_campaign=brand_conv_alone_exact_conv-dtc&utm_content=gatorade&utm_term=gatorade&gclid=e8c74e0e577b1bde4a162869d813c238&gclsrc=3p.ds&msclkid=e8c74e0e577b1bde4a162869d813c238", description: "Join Gatorade Score Rewards. Spend $5, Get points for a $5 coupon when you scan your first Gatorade receipt. Learn more on the Gatorade website!"},
                {image: "1_Diamond_Pepsi_Banner_Lipton.jpg", link: "https://www.lipton.com/us/en/", description: "Feels good, tastes great. Lipton Iced Tea with 100% Daily Value Antioxidant Vitamin C per 12fl oz. Learn more about it on the Lipton Iced Tea website!"},
                {image: "1_Diamond_Pepsi_Banner_PureLeaf.jpg", link: "https://www.pureleaf.com/our-products/", description: "Real variety, real brewed. Try Pure Leaf brewed tea. Available in No Sugar, Zero sugar sweet tea, Blackberry, and raspberry flavors. Learn more about it on the Pure Leaf website!"},
                {image: "1_Diamond_Pepsi_Banner_Rockstar.jpg", link: "https://www.rockstarenergy.com/", description: "Energy and mental boost. Try Rockstar Energy Drink. No available in Orange Pineapple, White Peach, and Lemon Lime flavors. Learn about it on the Rockstar Energy website right now!"},
                {image: "2_Platinum_FritoLay_Banner_Cheetos.png", link: "https://www.cheetosdejatuhuella.com/", description: "Salsa and Queso. Try new Cheetos flavor Deja Tu Huella now. Also, visit the Cheetos website to enter for a chance to win tickets to Billboard Latin Music Week in Miami!"},
                {image: "2_Platinum_FritoLay_Banner_ChipsSoda.png", link: "https://www.fritolay.com/brands", description: "Taste more summer. Enjoy the summer with a variety of Frito Lay and Pepsi products including Tostitos, Lays, Doritos, Pepsi, Starry, and Mountain Dew. Visit the Frito Lay website to learn more."},
                {image: "2_Platinum_FritoLay_Banner_Soccer.png", link: "https://www.fritolay.com/brands", description: "Get match ready. Try Lays classic or Doritos today. Learn more on the Frito Lay website."},
                {image: "2_Platinum_FritoLay_Banner_SummerFlavors.png", link: "https://www.lays.com/", description: "Summer means... Flavors for days! Try Lays newest flavors including Salsa Fresca and Wavy Cuban Sandwich. Learn more about them on the Frito Lay website."},
                {image: "2_Platinum_Mondelez_Banner_BelVita.jpg", link: "https://www.snackworks.com/brands/belvita", description: "Rise and thrive with Coffee's favorite biscuit, Belvita. Try Belvita now. Learn more about it on the Belvita website."},
                {image: "2_Platinum_Mondelez_Banner_ChipsAhoy.jpg", link: "https://www.snackworks.com/brands/chips-ahoy", description: "Here for ice cream, here for happy. Try chips ahoy with your favorite ice cream now. Learn more about it on the Chips Ahoy website."},
                {image: "2_Platinum_Mondelez_Banner_Oreo.jpg", link: "https://www.oreo.com/?cjdata=MXxOfDB8WXww&cjevent=c45b0038278311ef8156000d0a1cb82a", description: "Twist lick dunk. Make memories with milk's favorite cookie, Oreos. Learn more on Oreo's website right now!"},
                {image: "2_Platinum_Mondelez_Banner_Ritz.jpg", link: "https://snackworks.com/brands/ritz/", description: "Try Ritz crackers original or Toasted chips flavor. Learn more on the Ritz website right now."},
                //{image: "2_Platinum_GeneralMills_Banner_Totinos.jpg", link: "https://www.totinos.com/", description: "After-school hunger? You know what to do. Try Totino's Pizza Rolls. Learn about them from the Totino's website right now!"},
                {image: "3_GeneralMills_Banner_Cheerios.jpg", link: "https://www.cheerios.com/", description: "Expectedly Nutritious, Surprisingly delicious. Try Cheerios Veggie Blends Apple Strawberry and Blueberry Banana flavors today. Learn more on the Cheerios website."},
                {image: "3_GeneralMills_Banner_NatureValley.jpg", link: "https://www.naturevalley.com/", description: "Discover Nature Valley Lunch Box Bars. Learn more about these flavors on the Nature Valley website."},
                {image: "3_GeneralMills_Banner_Totinos.jpg", link: "https://www.totinos.com/", description: "Wake up your tastebuds. Roll out of bed for Tostino's Breakfast snack bites. Available in Bacon and Cheese, Sausage and Cheese, and Cheesy Chiorizo flavors. Learn more about it on the Tostinos website."},
                {image: "3_Gold_GeorgiaPacific_Banner_AngelSoft.jpg", link: "https://www.angelsoft.com/", description: "Angels Soft has 20% more sheets than the leading premium brands mega rolls. Learn more on the Angel Soft website now."},
                {image: "3_Gold_GeorgiaPacific_Banner_Brawny.jpg", link: "https://www.brawny.com/", description: "Brawny is 2x more durable than the leading store brand mainstream towel. Shop Brawny now on their website."},
                {image: "3_Gold_GeorgiaPacific_Banner_QN.jpg", link: "https://www.quiltednorthern.com/?gclid=e7581ba641ad1c09566c7dc1d38ab122&gclsrc=3p.ds&msclkid=e7581ba641ad1c09566c7dc1d38ab122&utm_source=bing&utm_medium=cpc&utm_campaign=QN_Brand_Exact&utm_term=quilted%20northern&utm_content=Home_Brand_Exact", description: "Ultimate plushness with 3 cushiony layers. Learn more about Quilted Northern on their website now!"},
                //{image: "3_Gold_Hormel_Banner_AllBrands.jpg", link: "https://www.hormelfoods.com/", description: "Planters, a nut above. Try Hormel's various brands including Jennie O, Hormel Natural Choice, Herdez, Columbus Craft Meats, Spam, Hormel Gatherings, and Hormel Pepperoni. Discover more on the Planter's website."},
                //{image: "3_Gold_KeurigDrPepper_Banner_Core.jpg", link: "https://hydratewithcore.com/", description: "Core Hydration, the official water partner of USA Gymnastics. Try Immunity, Vibrance, and Calm flavors. Learn about them from the Core Hydration website right now!"},
                //{image: "3_Gold_KeurigDrPepper_Banner_Juice.jpg", link: "https://www.keurigdrpepper.com/en/our-brands/product-facts-brands", description: "Sip into summer. Try Clamato tomato juice, Mott's apple juice, Snapple apple juice, Polar Seltzer, Bai Antioxidant Infusion, and Green Mountain Brew Coffee. Learn about them from the Keurig Dr. Pepper website right now!"},
                //{image: "3_Gold_KraftHeinz_Banner_Ketchup.jpg", link: "https://www.heinz.com/", description: "Discover Heinz's thick, rich and more to love picnic pack, includes a bottle of sweet relish, yellow mustard, and 2 bottles of tomato ketchup. Learn about them from the Heinz website now."},
                //{image: "3_Gold_TaylorFarms_Banner_AllBrands.jpg", link: "https://www.taylorfarms.com/", description: "Try Taylor Farms' various products including Good Times Vegetable tray with ranch dip, Taylor Farms' Avocado Ranch Chopped Kit, and Earthbound Farm Organic Baby Arugula. Learn about them on the Taylor Farms website right now!"},
                //{image: "3_Gold_TaylorFarms_Banner_EarthBound Farms.jpg", link: "https://www.taylorfarms.com/", description: "Freshest greens on Earth. Try Earthbound Farm Organic's various products including Baby Spinach, Spring Mix, and 50/50 Spinach Spring Mix. Learn about them on the Earthbound Farm Organic's website right now."},
                //{image: "3_Gold_TaylorFarms_Banner_Salads.jpg", link: "https://www.taylorfarms.com/", description: "Greens in every color. Try Taylor Farms' various products including Asian Chopped Kit, Caesar Chopped Kit, and Sweet Kale Chopped Kit. Learn about them on the Taylor Farms website right now!"},
                {image: "3_Gold_Unilever_Banner_UnileverBrands.jpg", link: "https://www.unilever.com/brands/", description: "Stock up on Unilever favorites including Ben & Jerry's, Best Foods, Breyers, Dove, Klondike, Knorr, Popsicle, Talenti, and Tresemme. Learn more about all of these on the Unilever website right now!"},
                //{image: "3_Gold_Unilever_Banner_Food.jpg", link: "https://www.unilever.com/brands/", description: "Everyday favorites that shine. Grab more of what you love. Try Best Foods mayonnaise, Ben and Jerry's ice cream, Breyers ice cream, Knorr alfredo pasta, Talenti mini sorbetto bars, Magnum ice cream, and Klondike ice cream bars. Learn about them from the Unilever website right now!"},
                //{image: "3_Gold_Unilever_Banner_HBC.jpg", link: "https://www.dove.com/us/en/washing-and-bathing/beauty-bar.html?gclid=ba378735c09f10fb1316e38b06671139&gclsrc=3p.ds&msclkid=ba378735c09f10fb1316e38b06671139&utm_source=bing&utm_medium=cpc&utm_campaign=EN_SKNCLN_Brand-Bar-General-Upper-Funnel_Inf_Txt_CPC_Brand_EM&utm_term=dove%20soap&utm_content=Brand_na_Brand-General-Beauty-Bar-Upper-EM", description: "Everyday favorites that shine. Grab more of what you love. Try Seventh Generation dish soap, Seventh Generation Laundry Soap, Dove Soap, Dove Shampoo, Dove Men shampoo, and Dove men deodorant. Learn about them from the Unilever website right now!"},
                {image: "4_Silver_7UP_Banner_7UPBrands.jpg", link: "https://www.7up.com/en#/recipes", description: "Sip into summer. Try 7UP 12 packs, Polar Seltzer Pomegranate 8 Packs, Canada Dry Ginger Ale, Core Hydration Water 6 packs, and Bai Antioxidant Infusion. Learn about them from the 7Up website right now!"},
                {image: "4_Silver_Bimbo_Banner_Artesano.jpg", link: "https://saraleebread.com/our-breads/artesano-bread", description: "Your way to gourmet. Artesano is the official bun of the chicken sandwich. Learn about them from the Artesano website right now!"},
                {image: "4_Silver_Bimbo_Banner_Artesano2.jpg", link: "https://saraleebread.com/our-breads/artesano-bread", description: "Your way to gourmet. Artesano is the official bun of the chicken sandwich. Learn about them from the Artesano website right now!"},
                {image: "4_Silver_KraftHeinz_Banner_MacandCheese.png", link: "https://www.kraftmacandcheese.com/?gclid=fb9e3b10714a15974ad25d7cca0b8a22&gclsrc=3p.ds&msclkid=fb9e3b10714a15974ad25d7cca0b8a22", description: "Any time, any way. Cheesy, made easy. Try Mac and Cheese today! Learn more on their website."},
                {image: "4_Silver_KraftHeinz_Banner_Velveeta.png", link: "https://www.kraftheinz.com/velveeta", description: "Melty creamy queso. Try Velveeta's products now! Learn more on their website."},
                //{image: "4_Silver_Campbells_Banner_Goldfish.png", link: "https://www.pepperidgefarm.com/product-categories/goldfish-crackers/", description: "Go for the handful. Try Goldfish crackers. Learn about them from the Goldfish website right now!"},
                //{image: "4_Silver_Campbells_Banner_Kettle.png", link: "https://www.kettlebrand.com/products/", description: "Bold flavor. Kettle cooked. Air fried to perfection. Try Kettle Brand Chips in various flavors including Sea Salt and Vinegar, Jalapeno, and Himalayan Salt. Learn about them from the Kettle website right now!"},
                //{image: "4_Silver_MissionFoods_Banner_Tortillas.jpg", link: "https://www.missionfoods.com/products/tortillas/", description: "Make today delicious. The possibilities of a new day are endless when you start with the great taste of Mission Tortillas. Try Mission's various products including Flour Tortillas Soft Taco, Flour Tortillas Burrito, and Carb Balance. Learn about them from the Mission website right now!"},
                {image: "4_Silver_Mission_Banner_Guerrero.jpg", link: "https://guerrerotortillas.com/", description: "Goal! Never miss when you have Guerrero. Learn more about Guerrero's products on their website now!"},
                {image: "4_Silver_PG_Banner_CharminBounty.png", link: "https://us.pg.com/brands/#Home-Care#Family-Care#Fabric-Care", description: "Trusted softness, better tear with Charmin. Do more, use less with Bounty. With Bounty, choose your sheet size based on the size of your mess. More sheets, more everyday tasks in every roll. More absorbent so you can use less. Learn more about them on the P&G website now."},
                {image: "4_Silver_PG_Banner_Tide.png", link: "https://us.pg.com/brands/#Family-Care", description: "Your laundry, your way. Discover a laundry experience that's all your own. Try Tide, Downy, and Bounce products. Learn more on the P&G website now."},
                //{image: "5_Bronze_Crossmark_Banner_ChallengeButter.jpg", link: "https://challengedairy.com/butter", description: "Churned fresh daily since 1911. Try Challenge salted butter. Learn about it on the Challenge Dairy website!"},
                //{image: "5_Bronze_Danone_Banner_AllFlavors.jpg", link: "https://www.internationaldelight.com/", description: "Get down with your sweet self. Try Delight Coffee creamer today! Flavors include Sweet and Creamy, French Vanilla, and Hazelnut. Learn about them on the Delight website!"},
                //{image: "5_Bronze_Dreyers_Banner_Haagen-Dazs.jpg", link: "https://www.icecream.com/us/en/brands/haagen-dazs", description: "That's Dazs. Try Haagen-Dazs ice cream in various flavors including Vanilla and vanilla milk chocolate almond. Learn about them on the Haagen-Daz website!"},
                //{image: "5_Bronze_Flower_Banner_Nature'sOwn.jpg", link: "https://www.naturesownbread.com/", description: "Go Back to school with Nature's Own. No artificial preservatives, colors or flavors. Flavors include Honey Wheat and Butterbread. Learn about them on the Nature's Own website today!"},
                {image: "5_Bronze_Clorox_Banner_CloroxBrands.jpg", link: "https://shop.clorox.com/products", description: "Here for the cookout, and the cleanup. Try a wide range of Clorox products. Learn more about all the brands Clorox has to offer on their website."},
                {image: "5_Bronze_RedBull_Banner_RedBull.png", link: "https://www.redbull.com/us-en/", description: "Choose your wings. Try Red Bull, Red Bull Sugar Free, and The Red Edition Watermelon flavor today. Learn about them on the Red Bull website!"}
            ],
        };
    },

    asyncComputed: {
        hideAppPromo: {
            get() {
                return new Promise((resolve) => {
                    if (process.appVersion) {
                        resolve(true);
                    } else if (this.$route.name === "download-app") {
                        resolve(true);
                    } else {
                        cookie.get("hide-app-promo").then(resolve);
                    }
                });
            },
            watch() {
                return this.hideAppPromoRefresh;
            },
        },
    },

    computed: {
        CTAButton() {
            if (this.showEnterCodeButton) {
                return {
                    to:    {name: 'enter-code'},
                    label: 'Enter Code'
                };
            }
            if (this.showPlayTheGameButton) {
                return {
                    to:    {name: 'returning-player'},
                    label: 'Play the Game'
                };
            }
            return null;
        },
        showPlayTheGameButton() {
            // not logged in, are allowed to login/register, and not already on the login/register page
            return !this.$store.get("auth/isLoggedIn") &&
                this.$store.get('auth/allowLoginOrRegister') &&
                ['returning-player', 'new-player'].indexOf(this.$route.name) < 0
        },
        readyToShowView() {
            if (process.appVersion) {
                return this.$store.get('auth/ready')
            }
            return this.$store.get('auth/ready') && this.$store.get('router/ready2');
        },
        showSpendTokensButton() {
            let allow = this.$store.get("auth/allowSpendTokens");
            allow = allow && this.$route.name !== "rewards-and-sweepstakes";
            return allow;
        },
        showEnterCodeButton() {
            let allow = this.$store.get("auth/allowEnterCodes");
            allow = allow && this.$route.name !== "enter-code" && this.$route.name !== 'match';
            return allow;
        },
        isInMatch() {
            return this.$route.name === 'match';
        },
        isHome() {
            return this.$route.name === 'home';
        },
        isPortrait() {
            return window.innerHeight > window.innerWidth;
        },
        showBanner() {
            // Only for home and registration pages for this game
            //return this.$route.name === 'home' || this.$route.name === 'new-player';
            // Show banners for multiple visitor pages and enter code page in 2024
            return this.$route.name === 'home' || this.$route.name === 'new-player' || this.$route.name === 'returning-player' || this.$route.name === 'enter-code';
        },
        showSponsors() {
            // Do not show sponsors for 2024
            return false;
        },
        showFooterMessages() {
            return (
                this.$config.env === "local" &&
                !this.$store.get("global/isApp") &&
                this.$store.get("auth/messages").length
            );
        },
        showAppPromoSection() {
            return !this.$store.get("global/isApp") && !this.hideAppPromo;
        },
        bodyClasses() {
            let $body = $('body');
            $body.toggleClass('overflow-hidden', !this.readyToShowView);
            $body.toggleClass('show', this.readyToShowView);
            $body.removeClass('customer employee associate');
            let currentUserClass = (this.$store.get("auth/currentUserType") || '').toLowerCase();
            $body.addClass(currentUserClass);
            // let showHeaderBottom = this.$store.get('auth/showRemainingTokens')|| this.$store.get("global/screenWidth") < 1200;
            let showHeaderBottom = false;
            return {
                "logged-in":           this.$store.get("auth/isLoggedIn"),
                tester:                this.$store.get("auth/isTester"),
                "show-header-bottom":  showHeaderBottom,
                "ad-blocker-detected": this.$store.get(
                    "global/adBlockerEnabled"
                ),
                "has-notice":          this.$store.get("auth/messages").length,
                [(
                    currentUserClass
                ).toLowerCase()]:      true,
            };
        },

        tickerStyle() {
            let style = {
                "padding-left": this.$store.get("global/screenWidth") + "px",
            };

            return style;
        },

        modalsVisible() {
            return !!this.$modalService.getVisibleModals().length;
        },


        showCountdown() {
            return (
                this.$store.get("auth/sessionData@gameStarts") &&
                !this.$store.get("global/isApp")
            );
        },
        showUpcomingSweepstakes() {
            let s = this.$store.get("auth/sweeps");
            return s && Object.keys(s).length > 0;
        },
        showMobileNavToggle() {
            console.log('showMobileNavToggle', this.$route.name);
            return this.$route.name !== 'home';
        },
        headerBottomClasses() {
            let showTokenBar = this.$store.get('auth/showRemainingTokens');
            if (showTokenBar) {
                return null;
            }
            // only visible if < xl
            return 'd-xl-none';
        },
        pickBanner() {
            let index = Math.floor(Math.random() * this.banners.length);
            return this.banners[index];
        }
    },

    created() {
        $("body").attr("tabindex", 0);

        window.state = this.$store.state;
        let loadContentFromLangFile = process.appVersion;
        // loadContentFromLangFile = process.appVersion || this.$config.env === 'local' ;

        if (process.appVersion) {
            if ($('script[src*="cordova.js"]').length == 0) {
                $("<script/>").attr("src", "cordova.js").appendTo("body");

                document.addEventListener("deviceready", () => {
                    window.plugins.webintent.getUri(function (url) {
                        try {
                            window.legacyToken = new URL(url).searchParams.get('token');
                        } catch (ignore) {
                            // invalid URL, just ignore the error
                        }
                    });
                    window.handleOpenURL = function (url) {
                        try {
                            window.legacyToken = new URL(url).searchParams.get('token');
                        } catch (ignore) {
                            // invalid URL, just ignore the error
                        }
                    }

                    this.$EventBus.$emit("deviceready");

                    document.addEventListener("backbutton", this.backButtonRaw);

                    // this.$store.dispatch("watchConnectivity");
                });
            }
        } else {
            this.$EventBus.$emit("deviceready");
        }

        // Start loading language files
        if (loadContentFromLangFile) {
            this.$store.dispatch("refreshJsonFiles");
        }

        this.$watch(
            "$route",
            (to, from) => {
                this.backPressedQuit = false;

                from = from || {name: "unknown"};
                to = to || {name: "unknown"};
                let toClassName   = to.name
                        ? to.name.toLowerCase().replace(/[^\w-]/g, "-") +
                        "-route"
                        : "home-route",
                    fromClassName = from.name
                        ? from.name.toLowerCase().replace(/[^\w-]/g, "-") +
                        "-route"
                        : "home-route";
                $("body").removeClass(fromClassName).addClass(toClassName);
            },
            {immediate: true}
        );

        let body = $("body");

        body.toggleClass("is-mobile", this.$store.get("global/isMobile"));
        if (this.$config.env !== "production") {
            body.addClass(this.$config.env + "-env");
        }

        let e = window,
            a = "inner";
        if (!("innerWidth" in window)) {
            a = "client";
            e = document.documentElement || document.body;
        }

        $(window)
            .resize(() => {
                let width = e[a + "Width"];
                this.$store.set("global/screenWidth", width);
            })
            .resize();

        // Listen for postMessages
        window.addEventListener(
            "message",
            (event) => {
                this.$EventBus.$emit("postMessage", event);
            },
            false
        );

        /** Listen for code scan events
         *  Tried using the $EventBus and also document level events, but
         *  the $modalService would not work and $EventBus tends to queue
         *  up events for some unknown reason.
         * @param code
         */
        // window.sendBarcode = (code) =>{
        // 1. redirect user to scan-code page
        // 2. ajax submit the code to the server
        // this.$router.push({name:'scan-code', params:{code:code}});
        // }
    },

    mounted() {
        $(this.$refs.nav).ADANavigation(null, function (e) {
            return $("#main-menu").hasClass("menu-open");
        });

        // Detect ad blockers
        window.blockAdBlock = new BlockAdBlock({
            // debug: process.env.NODE_ENV !== 'production'
        });

        blockAdBlock
            .onDetected(() => {
                // this.$store.commit("setAdBlockerEnabled");
            })
            .check();

        // conditionally create IE style tag
        // if (this.tickerStyleTag) {
        //   this.tickerStyleTag = $("<style/>").appendTo("body");
        // }

        // Wait for component to be rendered first
        // $(document).on("fully-rendered", ".ticker", () => {
        //   this.updateTickerAnimationSpeed();
        // });

        if (process.appVersion) {
            $(document).on("click", 'a[target="_blank"]', function (e) {
                if (e.isDefaultPrevented()) {
                    return;
                }

                e.preventDefault();
                window.open(this.href, "_system");
            });
        }

        $(document).on("offline online", (e) => {
            console.log("offline state change", e);
            this.$store.set("global/offline", e.type === "offline");
        });

        this.$watch(
            () => {
                return this.$store.get("auth/isLoggedIn");
            },
            (to) => {
                if (to) {
                    // $(this.$refs.main).css("min-height", "");
                } else {
                    let minHeight = $(window).height() - $("footer").height();
                    // $(this.$refs.main).css("min-height", `${minHeight}px`);
                }
            },
            {immediate: true}
        );

        // Stupid iframe trackers
        setInterval(() => {
            this.disableTabIframes();
        }, 1000);
    },

    methods: {
        loginUserAndEnterCodes(sessionData) {
            if (process.appVersion) {
                this.$store.set('auth/user', sessionData);
                this.$router.push({name: 'enter-code'});
                return;
            }
            let redirect = [
                location.protocol,
                '//',
                this.$config.loggedInDomain
            ];
            if (location.port) {
                redirect.push(`:${location.port}`)
            }
            redirect.push('/enter-code');
            redirect.push('?sessionid=' + sessionData.user.ephemeral_login_token)
            redirect = redirect.join('');
            location.href = redirect;
        },

        /**
         * toggleMenu(false) is invoked when an item is clicked from the nav menu
         * @param show
         */
        toggleMenu(show) {
            var self = $(this.$refs.navbarToggler);
            var target = $(self.data("target"));
            let open = self.attr("aria-expanded") == "true";
            if (typeof show === "boolean") {
                open = !show;
            }
            let $nav = $(this.$refs.nav);
            if (open) {
                self.attr("aria-expanded", "false");
                $nav.off("keydown");
                self.find(".sr-only").text("Show Navigation");
                target.attr("aria-hidden", "true");
            } else {
                self.attr("aria-expanded", "true");
                self.find(".sr-only").text("Close Button");
                target.removeAttr("aria-hidden");
            }

            target.toggleClass("menu-open", !open);
            $("body").toggleClass("main-menu-open", !open);
            this.$store.set("global/navExpanded", !open);
            if (show === false) {
                let focusable = $("body, .route-focus");
                if (navigator.userAgent.match(/iPad|iPhone/i)) {
                    // all iPhone/iPad browsers, because VoiceOver sucks
                    focusable = focusable.filter(
                        "a, button, input, select, textarea"
                    );
                }
                focusable.first().focus();
            }
        },

        dismissAppPromo() {
            cookie.set("hide-app-promo", true, {expires: 7}).then(
                () => {
                    this.hideAppPromoRefresh = !this.hideAppPromoRefresh;
                },
                () => {
                    // unable to write cookie
                }
            );
        },
        backButtonRaw() {
            let options = {
                cancel: false,
            };

            this.$EventBus.$emit("backbutton", options);

            if (!options.cancel) {
                this.backButton();
            }
        },
        backButton() {
            // Unfortunately, this completely overrides default functionality.
            // We must do history.back() to retain default action

            // Close a modal if possible
            if (!this.$modalService.close()) {
                if (
                    this.$route.name === "home" ||
                    this.$route.name === "enter-code"
                ) {
                    if (this.backPressedQuit) {
                        navigator.app.exitApp();
                    } else {
                        this.backPressedQuit = true;
                        plugins.toast.showShortBottom(
                            "Press the back button again to exit"
                        );
                        setTimeout(() => {
                            this.backPressedQuit = false;
                        }, 3000);
                    }
                } else {
                    history.back();
                }
            }
        },
        updateTickerWidth() {
            let ticker = $(".ticker");
            let width = 0;
            ticker.children().each((k, item) => {
                width += $(item).outerWidth();
            });
            ticker.width(width);
        },
        updateTickerAnimationSpeed() {
            this.updateTickerWidth();

            let ticker    = $(".ticker"),
                fullWidth = ticker.outerWidth();
            ticker.css("animation-duration", fullWidth / 200 + "s");

            if (this.tickerStyleTag) {
                this.tickerStyleTag.html(
                    "@keyframes ticker {\n" +
                    "\t0% {\n" +
                    "\t\ttransform: translate3d(0, 0, 0);\n" +
                    "\t\tvisibility: visible;\n" +
                    "\t}\n" +
                    "" +
                    "\t100% {\n" +
                    "\t\ttransform: translate3d(-" +
                    fullWidth +
                    "px, 0, 0);\n" +
                    "\t}\n" +
                    "}"
                );
            }
        },
        adaFocus(e, dontScroll) {
            let hash   = $(e.target).data("target"),
                target = $(hash);

            let focusable =
                    'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
            target = target.find(focusable);
            if (navigator.userAgent.match(/iPad|iPhone/i)) {
                // all iPhone/iPad browsers, because VoiceOver sucks
                target = target.filter("a, button, input, select, textarea");
            }
            target = target.first();

            if (dontScroll) {
                target.focus();
            } else {
                $.smoothAnchor(hash).then(() => {
                    target.focus();
                });
            }
        },

        disableTabIframes() {
            $("body > iframe").attr({
                tabindex:      "-1",
                "aria-hidden": "true",
            });
        },
    },

    watch: {
        tickerStyle() {
            this.$nextTick(() => {
                this.updateTickerAnimationSpeed();
            });
        },
    },
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
@import "~ladda/css/ladda.scss";
@import "~slick-carousel/slick/slick.css";
@import "./assets/css/style.scss";
</style>

<style lang="scss" scoped>
.cta-btn {
    position: absolute;
    right: 10px;
}

.site-logo {
    width: 100%;
    height: auto;
    @media(min-width: $md) {
        max-width: 100%;
    }
}

.header-bottom {
    height: $headerBottomHeight;
    padding: 0 15px;
    color: $darkgreen;
    font-weight: 900;
}

.accessibility-header .sr-only-focusable {
    &:focus {
        padding: 6px !important;
        z-index: 1;
        @media (min-width: $sm) {
            position: relative;
            left: 320px;
            top: -25px;
        }
    }
}

.col-phone {
    text-align: center;

    img {
        position: relative;
        max-width: 100px;
        box-shadow: 7px 10px 20px 0px rgba(0, 0, 0, 0.5);
        display: none;
        @media (min-width: $lg) {
            display: inline-block;
            max-width: 400px;
            box-shadow: 7px 10px 20px 0px rgba(0, 0, 0, 0.5);
            top: 80px;
        }
    }
}

.sponsors {
    background-color: $light;
}

.sponsored-by {
    width: 12vw;
}

.footer {
    // @include fontspecial;
    background-color: $green;

    .employee & {
        background-color: $employeePrimary;
    }

    a {
        color: $light;
    }

    position: relative;
    z-index: 0;
    // height: $footerHeight;
}

.top-logo {
    &:not(.w-30 &) {
        @media (max-width: $md - 1) {
            display: block;
            width: 40%;
        }
    }
}
.header-top-inner {
    > :nth-child(2) { order: 4; }
    > :nth-child(4) { order: 2; }
    @media (max-width: $lg - 1) {
        > :nth-child(2) { order: 3; }
        > :nth-child(3) { order: 4; }
    }
}

.reward {
    border-radius: 15px;
    padding: 25px 0;
    .arrow {
        vertical-align: sub;
    }
}
</style>
