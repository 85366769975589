<template>
	<div class="container-fluid">
		<div class="row page-title">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<h1 class="text-center py-0 py-md-2 m-0 roboto-900">HOW TO PLAY & WIN</h1>
			</div>
		</div>
		
        <div class="container">
            <!-- <div class="bg-white p-3 p-lg-5 my-5 mx-0 mx-lg-3 rounded-10 text-dark roboto-900 font-weight-normal"> -->
            <div class="bg-white p-3 p-lg-5 my-5 mx-0 mx-md-5 rounded-10 text-dark roboto-900 font-weight-normal">
                <div class="text-center text-md-left">
                    <img src="../assets/images/collect-and-win.png" alt="Collect & Win" class="img-htp-left img-fluid" />
                </div>
                <div class="row pl-5">
                    <div class="col-3 col-md-2 col-lg-1 pr-0">
                        <img src="../assets/images/howto1.png" alt="One" class="img-fluid" />
                    </div>
                    <div class="col-10 col-md-10 col-lg-11 font-weight-bold pt-2">
                        <h4 class="d-inline-block color-lightgreen roboto-900-italic mb-4">COLLECT & WIN</h4>
                        <p class="mb-0 font-weight-bold font-size-large">OPEN GAME TICKET</p>
                        <p class="mb-4">Inside every Game Ticket are Four (4) Game Markers.<br>Get a Game Ticket every time you shop. Earn BONUS Game Tickets when you purchase participating items. <a href="/files/SAV-02 MONOPOLY Bonus Game Ticket Item List.pdf" class="text-decoration-none color-green">BONUS ITEM LIST</a></p>
                        <p class="mb-0 font-weight-bold font-size-large">PUT GAME MARKERS ON THE GAME BOARD</p>
                        <p class="mb-4">Match and Attach the Game Markers to the corresponding spaces on the Official Game Board.</p>
                        <p class="mb-0 font-weight-bold font-size-large">COMPLETE A GAME PRIZE SECTION</p>
                        <p>Collect all the markers to complete a Prize Section, with that prize.</p>
                    </div>
                </div>
                <div class="text-center text-md-left">
                    <img src="../assets/images/instant-win-tickets.png" alt="Instant Win Tickets" class="img-htp-right img-fluid img-smaller" />
                </div>
                <div class="row mt-5 pl-5">
                    <div class="col-3 col-md-2 col-lg-1 pr-0">
                        <img src="../assets/images/howto2.png" alt="Two" class="img-fluid" />
                    </div>
                    <div class="col-10 col-md-10 col-lg-11 font-weight-bold pt-2">
                        <h4 class="d-inline-block color-lightgreen roboto-900-italic mb-4">INSTANT WIN</h4>
                        <p>Reveal an Instant Winner Message Inside Game Tickets To Win Cash, Fuel, Gift Cards, FREE Merchandise, or Rewards Points!</p>
                        <p class="color-lightgreen font-size-large">Win Up to $10,000 Instantly!<br>Plus MORE PRIZES in 2024!</p>
                        <p>Even if you don't win instantly, you still might reveal valuable money saving offers on many of your favorite brands and products.</p>
                    </div>
                </div>
                <div class="text-center text-md-left">
                    <img src="../assets/images/online-match-3-game-ticket.png" alt="Online Match 3 Game Ticket" class="img-htp-left img-fluid img-smaller pull-down" />
                </div>
                <div class="text-center text-md-left">
                    <img src="../assets/images/online-match-3-game.png" alt="Online Match 3 Game" class="img-htp-right img-fluid img-smaller pull-down" />
                </div>
                <div class="row mt-5 pl-5">
                    <div class="col-3 col-md-2 col-lg-1 pr-0">
                        <img src="../assets/images/howto3.png" alt="Three" class="img-fluid" />
                    </div>
                    <div class="col-10 col-md-10 col-lg-11 font-weight-bold pt-2">
                        <h4 class="d-inline-block color-lightgreen roboto-900-italic mb-4">ONLINE MATCH 3 GAME</h4>
                        <p class="mb-4 color-lightgreen font-size-large">BETTER ODDS TO WIN in 2024!</p>
                        <p class="mb-0 font-weight-bold font-size-large color-lightgreen">FIND A GAME CODE</p>
                        <p class="mb-4">Inside certain MONOPOLY Game Tickets you will find an online game code.</p>
                        <p class="mb-0 font-weight-bold font-size-large color-lightgreen">VISIT SavingsRoadtrip.com</p>
                        <p class="mb-4">Register then log in to enter your Game Code. All game codes must be entered by 10/15/2024.</p>
                        <p class="mb-0 font-weight-bold font-size-large color-lightgreen">TO PLAY THE MONOPOLY MATCH 3 GAME</p>
                        <p class="mb-4">Select any 3 of the Numbered Road Signs and if ALL 3 of your selected Road Signs match the randomly generated Numbered Winning Road Signs, you WIN the prize shown, from $25 up to $100,000!</p>
                        <p class="mb-0 font-weight-bold font-size-large color-lightgreen"">Don't match ALL 3 Numbered Winning Road Signs?</p>
                        <p>Every Game Code played enters you into the 2nd Chance Sweepstakes.</p>
                    </div>
                </div>
                <div class="row mt-5 pl-5">
                    <div class="col-3 col-md-2 col-lg-1 pr-0">
                        <img src="../assets/images/howto4.png" alt="Four" class="img-fluid" />
                    </div>
                    <div class="col-10 col-md-10 col-lg-11 font-weight-bold pt-2">
                        <h4 class="d-inline-block color-lightgreen roboto-900-italic mb-4">2ND CHANCE SWEEPSTAKES</h4>
                        <p class="mb-4">Every Online Game Code played enters you in the 2nd Chance Sweepstakes for $250 Save Mart or Lucky Gift Cards and $1,000 shopping sprees, $10,000 CASH and the $100,000 Guaranteed Prize, if necessary.</p>
                        <p class="mb-0 font-weight-bold color-lightgreen font-size-large">$100,000 GUARANTEED TO BE AWARDED!</p>
                        <p>If the $100,000 Collect & Win Prize is not claimed by the submission deadline of October 22, 2024, we will award one (1) $100,000 prize via our 2nd Chance Sweepstakes.</p>
                    </div>
                </div>
                <!-- <div class="text-center text-md-left">
                    <img src="../assets/images/2nd-chance-sweepstakes.png" alt="2nd Chance Sweepstakes" class="img-htp-right img-fluid img-smaller pull-up img-smallest" />
                </div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import store from "store";

	export default {
		name: "how-to-play",

		components: {
		},
		methods: {
		},
		mounted() {
		},
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
    .section-pull-left {
        @media(min-width: $md) {
            margin-left: -70px;
        }
        @media(min-width: $lg) {
            margin-left: -110px;
        }
        @media(min-width: $xl) {
            margin-left: -120px;
        }
        @media(min-width: $xxl) {
            margin-left: -250px;
        }
    } 
    .img-htp-left {
        width: auto;
        @media(min-width: $md) {
            position: absolute;
            left: 0;
            margin-top: 80px;
            width: 25%;
            padding-right: 30px;
        }
        @media(min-width: $lg) {
            padding-right: 50px;
        }
        @media(min-width: $xl) {
            padding-right: 70px;
        }
        @media(min-width: $xxl) {
            padding-right: 30px;
        }
        &.img-smaller {
            padding-right: 0; 
            @media(min-width: $md) {
                width: 25%;
                margin-top: 80px;
                padding-right: 30px;
            }
            @media(min-width: $lg) {
                width: 20%;
                padding-right: 0;
            }
            @media(min-width: $xl) {
                margin-left: 0;
                padding-right: 20px;
            }
            @media(min-width: $xxl) {
                margin-top: 60px;
                margin-left: 25px;
            }
        }
        &.pull-down {
            @media(min-width: $md) {
                margin-top: 100px;
            }
        }
    }

    .img-htp-right {
        width: auto;
        @media(min-width: $md) {
            position: absolute;
            right: 0;
            margin-top: 80px;
            width: 25%;
            padding-right: 30px;
        }
        @media(min-width: $lg) {
            padding-right: 50px;
        }
        @media(min-width: $xl) {
            padding-right: 70px;
        }
        @media(min-width: $xxl) {
            padding-right: 30px;
        }
        &.img-smaller {
            padding-right: 0; 
            @media(min-width: $md) {
                width: 25%;
                margin-top: 150px;
            }
            @media(min-width: $lg) {
                width: 20%;
                padding-right: 0;
            }
            @media(min-width: $xl) {
                margin-left: 0;
                padding-right: 20px;
            }
            @media(min-width: $xxl) {
                margin-top: 150px;
                margin-right: 25px;
            }
        }
        &.pull-up {
            @media(min-width: $md) {
                margin-top: -300px;
            }
        }
        &.img-smallest {
            @media(min-width: $md) {
                width: 18%;
                margin-top: -260px;
            }
            @media(min-width: 1921px) {
                width: 14%;
                margin-top: -360px;
            }
        }
    }
</style>
