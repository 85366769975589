<template>
    <modal v-bind="modalOpts">
        <h5 class="modal-title text-center" slot="header" v-html="busy?'Please wait...':title"></h5>
        <template v-if="busy">
            <div slot="body" class="text-center lead">
                <fa-icon icon="faCog" spin size="lg"></fa-icon>
            </div>
            <div slot="footer"></div>
        </template>
        <template v-else>
            <div slot="body" class="text-center">
                <div v-html="message"></div>
                <!-- todo: use v-model to bind the response token to a component data variable, then pass that value to the server -->
                <recaptcha v-if="captcha" v-model="captchaCode" ref="captchaControl"></recaptcha>
            </div>

            <div slot="footer">
                <button type="button" class="btn btn-primary btn-red btn-block" @click="okay" :disabled="!ready">Confirm</button>
                <button type="button" class="btn btn-primary btn-red btn-block" @click="modal.trigger('close')">No, I changed my mind</button>
            </div>
        </template>
    </modal>
</template>

<script>
import Recaptcha from '../../components/Recaptcha';

export default {
    name: 'generic',
    data() {
        return {
            busy:        false,
            captchaCode: ''
        }
    },
    props:      {
        modal:   {
            type:     Object,
            required: true
        },
        title:   {
            type:    String,
            default: 'Are you sure?'
        },
        message: String,
        theme:   String,
        captcha: Boolean,
        opts:    Object
    },
    components: {
        Recaptcha
    },
    computed:   {
        ready() {
            if (this.captcha && this.captchaCode.length < 6) {
                return false;
            }
            return true;
        },
        modalOpts() {
            let defaults = {
                classes: [],
            };

            return $.extend(defaults, this.opts || {});
        }
    },
    methods:    {
        okay() {
            let data = {};
            if (this.captcha) {
                data.captchaCode = this.captchaCode;
            }
            this.busy = true;
            this.modal.trigger('continue', data);
        }
    }
}
</script>
