<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="bg-white d-flex flex-column mx-auto my-5 rounded-8 p-4 p-xl-5 box-shadow-0-0-10">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="w-100">
                                <div class="font-weight-bold pb-3 font-size-large">Sign in</div>
                                <FormulateForm
                                    name="loginForm"
                                    class="mb-5"
                                    @submit="submit"
                                    :schema="loginForm"
                                    v-model="formData"
                                    :form-errors="formErrors"
                                >
                                    <recaptcha3 ref="captchaComponent" context="login" v-if="$config.useCaptcha" :siteKey="$config.recaptcha.siteKey"></recaptcha3>
                                    <submit-button class="btn btn-lightgreen btn-rounded color-light roboto-600 px-5 mt-4 d-inline-block" ref="btnSubmit" :loading="busy">Sign In</submit-button>
                                    <div class="align-middle mt-4 float-md-right text-md-right">
                                        <div class="font-size-sm mb-2">Having Trouble?
                                            <router-link :to="{name:'forgot-password'}">Recover password</router-link>
                                        </div>
                                        <div class="font-size-sm">Don't have an account?
                                            <router-link :to="{name:'new-player'}">Sign up</router-link>
                                        </div>
                                    </div>
                                </FormulateForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Recaptcha3 from '../components/Recaptcha3';
import config from '../startup/config'

export default {
    name:       "returning-player",
    components: {Recaptcha3},
    data() {
        return {
            busy: false,
            //https://vueformulate.com/guide/validation/#customize-validation-messages
            loginForm:  [
                {
                    name:       'email',
                    label:      'Email',
                    placeholder:'Email',
                    type:       'text',
                    validation: 'email',
                    class:      'col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top',
                    value:      ''
                },
                {
                    name:       'password',
                    label:      'Password',
                    placeholder:'Password',
                    type:       'password',
                    validation: 'required',
                    class:      'col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top',
                    value:      ''
                }
            ],
            formErrors: [],
            formData:   {}
        }
    },
    methods: {

        async submit(data) {
            this.busy = true;
            if (this.$refs.captchaComponent) {
                data['captcha'] = await this.$refs.captchaComponent.getToken();
            }
            let response = await this.$http.post('user/login', data).catch(({response}) => {
                this.busy = false;
                if (response.data.error) {
                    this.formErrors = [response.data.error.errorMessage];
                }
            });
            if (response) {
                this.$root.$children[0].loginUserAndEnterCodes(response.data);
            } else {
                if (this.$refs.captchaComponent) {
                    this.$refs.captchaComponent.reset();
                }
            }
        }
    }
};
</script>
<style lang="scss">
.winner-box {
    max-height: 400px;
}
</style>
