<template>
	<!--	blur handlers for the <buttons> don't seem to work well -->
	<div v-if="depth == 2" class="faq-item" role="group" :aria-labelledby="`label-${ndx}`">
		<button class="faq-question btn-collapse px-0" type="button" data-toggle="collapse" :data-target="'#faq-' + ndx" aria-expanded="false" :aria-controls="'#faq-' + ndx" v-html="titleHtml()" v-ada><i class="far fa-plus"></i></button>

		<div class="faq-answer collapse" :id="'faq-' + ndx">
			<div v-if="children" class="collapse-inner no-pad">
				<template v-for="(item, i) in children">
					<faq-component v-if="isChildComponent(item)" v-bind="item" :depth="depth + 1" :key="i"/>
					<div class="faq-content-block font-size-small" v-else :key="i" v-html="itemHtml(item)"></div>
				</template>
			</div>
		</div>
	</div>
	<div v-else class="faq-item">
		<button class="faq-question btn-collapse px-0" type="button" data-toggle="collapse" :data-target="'#faq-' + ndx" aria-expanded="false" :aria-controls="'#faq-' + ndx" v-html="titleHtml()" v-ada><i class="far fa-plus"></i></button>

		<div class="faq-answer collapse" :id="'faq-' + ndx">
			<div v-if="children" class="collapse-inner no-pad">
				<template v-for="(item, i) in children">
					<faq-component v-if="isChildComponent(item)" v-bind="item" :depth="depth + 1" :key="i"/>
					<div class="faq-content-block font-size-small" :key="i" v-html="itemHtml(item)"></div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	let ndx = 0;

	export default {
		name: 'faq-component',

		props: {
			title:    String,
			children: Array,
			depth:    {
				type:    Number,
				default: 2
			}
		},

		data() {
			return {
				ndx: ndx++,

			};
		},
		methods: {
			itemHtml(item) {
				if (!this.$store.get('global/isApp')) {
					return item.replace('#/contact', '/contact');
				}
				return item;
			},
			isChildComponent(item) {
				return typeof item == 'object';
			},
			titleHtml() {
				return `
					<h${this.depth} class="font-size-small font-normal m-0" id="label-${this.ndx}">${this.title}</h${this.depth}>
				`;

			},

		}
	}
</script>

<style lang="scss" scoped>


	.faq-content-block {
		padding: 30px 30px 30px 0;

		::v-deepp {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&:empty {
			padding: 0;
		}
	}

	.faq-question {
		width: 100%;
		text-align: left;
		border: 0;
		background: transparent;
		font-weight: 400;
		position: relative;
		padding: 18px 40px 14px 20px;
		text-transform: uppercase;
		border-bottom: 1px solid $lightgray;
		text-decoration: none;
		
		::v-deep{
			h2, h3, h4, h5, h6, .font-normal {
				text-transform: none;
				color: $dark;
				font-weight: 700;
				font-size: 18px;
			}
		}
		
		// .employee & {
		// 	background-color: $darkgreen;
		// }

		&::before,
		&::after {
			content: "";
			position: absolute;
			right: 10px;
			top: calc(50% - 8px);
			background: $lightgreen;
			border-radius: 2px;
			transition: opacity 250ms;
			opacity: 1;
			color: $blue;
		}

		&::before {
			width: 18px;
			height: 2px;
			margin-top: 8px;
		}

		&:after {
			width: 2px;
			height: 18px;
			margin-right: 8px;
		}

		&[aria-expanded="true"] {
			&::after {
				opacity: 0;
			}
		}
	}

	.faq-answer {
		padding: 0 0 0 30px;
		color: $dark;
	}

	.faq a {
		color: $dark;
		word-break: break-word;
	}

</style>
