<template>
	<modal @close="$emit('event', 'close')" v-bind="modalOpts">
		<h5 class="modal-title " slot="header" v-html="title"></h5>
		<div class="" slot="body" v-html="message"></div>
		<div slot="footer">
			<button type="button" class="btn btn-primary btn-red" @click="refreshPage" v-ada>Retry Now</button>
		</div>
	</modal>
</template>

<script>
    import {setStorage} from '@/utils/storage'

    export default {
        name:     'offlineModal',
        props:    {
            title:   String,
            message: String,
            opts:    Object
        },
        computed: {
            isBoxTrackingTheme() {
                return /box-tracking/.test(this.theme)
            },
            modalOpts() {
                let defaults = {
                    classes: ['modal-stars', 'unclosable']
                };

                return $.extend(defaults, this.opts || {});
            }
        },
		methods:{
            refreshPage() {
                setStorage('temp_auth', this.$store.get('auth/isLoggedIn'), () => {
                    window.location.reload();
                });
            }
		}
    }
</script>
