<template>
	<div class="form-group">
		<img class="img-responsive" :src="imageUrl">
		<button type="button" class="btn btn-primary btn-red btn-sm" @click="reset">
			<fa-icon icon="faSyncAlt" pack="solid" v-ada></fa-icon>
		</button>
		<br>
		<label>
			Type the characters in the box below:<br>
			<input type="text" class="form-control" :value="value" @input="onInput" />
		</label>
	</div>
</template>

<script>
	// Using the recaptcha store

	// TODO: update to allow v3 use, and stop using vue-recaptcha
    // use v2-invisible or else do extra programming to support v3 (alternatively, use vue-recaptch3 add-in)
	export default {
		name: 'Recaptcha',

		props: {
			value: String,


            useV3:Boolean,

			theme: {
				type:      String,
				default:   'light',
				validator: function (val) {
					if (val == 'light' || val == 'dark') {
						return true;
					}

					return false;
				}
			},

			classes: {
				default:   '',
				validator: function (val) {
					if (Array.isArray(val)) {
						for (var i = 0; i < val.length; i++) {
							if (typeof val[i] !== 'string') {
								return false;
							}
						}
					} else if (typeof val !== 'string') {
						return false;
					}

					return true;
				}
			},
		},

		data() {
			return {
				id:          false,
				time:        false,
				captchaCode: ''
			};
		},

		computed: {
			classesArr() {
				let classes = ['g-recaptcha'];

				if (Array.isArray(this.classes)) {
					classes = classes.concat(this.classes);
				} else {
					classes = classes.concat(this.classes.split(' '));
				}

				return classes;
			},
			imageUrl() {
				let uri = this.$config.serverUrl + '/app/generateCaptcha';
				if (this.time) {
					uri += '?t=' + this.time;
				}

				return uri;
			}
		},

		methods: {

			onInput(event) {
				this.$emit('input', event.target.value)
			},
			reset() {
				if (this.$store.get('global/isApp')) {
					this.time = (new Date()).getTime();
				} else {
					// if (this.$store.get('recaptcha/status') != 'loaded' || this.id === false) {
					// 	return false;
					// }
					//
					// grecaptcha.reset(this.id);
				}

				return true;
			},

			getResponse() {
				// todo implement v-model instead.
				if (this.$store.get('recaptcha/status') != 'loaded' || this.id === false) {
					return false;
				}

				return grecaptcha.getResponse(this.id);
			},

			_renderCaptcha() {
				let container = document.createElement('div');
				$(this.$refs.captcha).append(container);

				this.id = grecaptcha.render(container, {
					sitekey:  this.$store.get('recaptcha/siteKey'),
					theme:    this.theme,
					callback: (responseKey) => {
						this.$emit('validated')
					}
				});
			},

			_getScript() {
				this.$store.set('recaptcha/status', 'loading');

				window._recaptcha_load_cb = () => {
					this.$store.set('recaptcha/status', 'loaded');
				};

				$.getScript('https://www.google.com/recaptcha/api.js?onload=_recaptcha_load_cb&render=explicit', () => {
					// yay
				}).fail(() => {
					this.$store.set('recaptcha/status', 'error');
				});
			}
		},

		mounted() {
			if (!process.appVersion) {
				this.$watch('$store.state.recaptcha.status', (newStatus) => {
					if (newStatus == 'loaded') {
						this._renderCaptcha();
					}
					else if (newStatus == 'unloaded') {
						this._getScript();
					}
				}, {immediate: true});
			}
		},

		watch: {
			'theme'() {
				$(this.$refs.captcha).html('');
				this._renderCaptcha();
			}
		}
	}
</script>

<style scoped>
	iframe {
		border: none;
		outline: none;
	}
</style>
