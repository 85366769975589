<template>
	<div class="main main-privacy">
		<div class="container-fluid">
			<div class="row page-title">
				<div class="col-4 d-flex align-items-center justify-content-start">
                    <div class="text-center text-md-left" :class="isPortrait ? 'w-30' : ''">
                        <a href="https://savemart.com" target="_blank" class="top-logo mr-md-5">
                            <img  src="../assets/images/savemart.png" alt="Go to Save Mart" class="img-fluid">
                        </a>
                        <a href="https://luckysupermarkets.com" target="_blank" class="top-logo">
                            <img  src="../assets/images/lucky.png" alt="Go to Lucky Supermarkets" class="img-fluid">
                        </a>
                    </div>
				</div>
				<div class="col-4  d-flex align-items-center justify-content-center">
					<h1 class="text-center py-5 m-0">Privacy Policy</h1>
				</div>
			</div>
			<div class="box bg-green p-4 p-lg-5 mt-5 border-2">
				<div class="container">
					<p>Coming Soon</p>
				</div>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "privacy",
		data() {
			return {};
		},
		mounted() {
			$(".main-privacy a").attr({ target: "_blank", rel: "noopener" });
		}
	};
</script>

<style lang="scss" scoped>
h2 {
    @include h4;
}
</style>
