<template>
	<div class="container-fluid">
		<div class="row page-title">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<h1 class="text-center py-0 py-md-2 m-0 roboto-900">Winners</h1>
			</div>
		</div>

        <div class="container">
            <div class="bg-white p-3 p-lg-5 my-5 mx-0 mx-lg-3 rounded-10">
                <div v-if="showWinners && potentialWinners" class=" match3-winners">
                    <p class="text-center color-lightgreen font-size-title font-weight-bold">Match 3 Potential Winners*</p>
                    <p class="text-center font-size-large font-weight-bold">Prize Quantity of Winners</p>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3" v-for="potentialWinner in potentialWinners">
                            <div class="border prize-card mb-4">
                                <div class="text-center font-size-banner font-weight-bold py-4 number-winners">{{potentialWinner.number}}</div>
                                <div class="text-center bg-green color-light py-3 prize-winners">
                                    <p class="roboto-900-italic font-size-largest mb-0">{{potentialWinner.prize}}</p>
                                    <p class="font-size-large mb-0">WINNERS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-0 px-md-5 font-size-large py-3">* Number of customers who matched all three numbers they chose, but are not verified winners until prize form and original ticket code have been received.</div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <h4 class="text-dark text-center py-5">No potential winners at the moment.</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="my-5 mx-0 mx-lg-3 text-dark">
                <div v-if="showWinners && winners" class="winners">
                    <b-tabs content-class="bg-white p-3 pb-lg-5 px-lg-5 rounded-bottom-10">
                    <template v-for="(winner, index) in winners">
                        <b-tab :key="index" :class="(index==0)?'active':''">
                            <template #title><span v-html="winner.type"></span></template>
                            <div v-for="user in winner.users" class="row mx-3 py-3 align-items-center text-center text-md-left winner">
                                <div class="col-12 col-md-4 p-0">
                                    <p class="font-weight-bold font-size-large m-0">{{ user.name }}</p>
                                </div>
                                <div class="col-12 col-md-4 p-0">
                                    <p class="font-weight-bold font-size-large m-0">{{ user.location }}</p>
                                </div>
                                <div class="col-12 col-md-4 p-0">
                                    <p class="font-weight-bold font-size-large m-0">{{ user.prize }}</p>
                                </div>
                            </div>
                        </b-tab>
                    </template>
                    </b-tabs>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <h4 class="text-dark text-center py-5">No winners at the moment.</h4>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>


<script>
	import Vue from "vue";
	import {get} from "vuex-pathify";
	import {format} from 'date-fns';

	export default {
		name: "winners",

		components: {
		},

		data() {
			return {
				slickOptions:         {
					autoplay:      true,
					autoplaySpeed: 1500,
					arrows:        false
				},
                potentialWinners: null,
				showWinners:          this.$store.get('auth/sessionData').showWinners,
				showCustomHtmlBlock1: this.$store.get('auth/sessionData').showWinnersHtmlBlock1,
				showCustomHtmlBlock2: this.$store.get('auth/sessionData').showWinnersHtmlBlock2,
				lastYear:             format(new Date() - 1, 'yyyy'),
				thisYear:             format(new Date(), 'yyyy')
			};
		},
        created() {
            this.$http.get("/game/winners").then(
                response => {
                    this.potentialWinners = response.data;
                }
            ).catch(xhrError=>{
                let msg = 'Winners are unavailable at this time. Please try again in a few minutes.';
                let detail=_get(xhrError, 'response.data', msg);
                if (process.env.NODE_ENV!=='production'){
                    msg = detail;
                }
                this.errorMessage=msg;
            })
        },
		asyncComputed: {
			/**
			 * Create the file winners.json inside these two directories:
			 * ./public/static/files/
			 * ./src/frontend/static/files/
			 *
			 */
			winners:                 {
				get() {
					if (!this.showWinners) {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl+"winners.json")
							.then(response => {
								resolve(response.data.winners);
							})
							.catch(error => {
								this.showWinners = false
								reject(error.message);
							});
					});
				},
			},
			winners_photos_customer: {
				get() {
					if (!this.showWinners || this.userType !== 'customer') {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl+"winners_photos_customer.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								reject(error.message);
							});
					});
				},
			},
			winners_photos_employee: {
				get() {
					if (!this.showWinners || this.userType !== 'employee') {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl+"winners_photos_employee.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								reject(error.message);
							});
					});
				},
			},
			customHtmlBlock1:        {
				get() {
					if (!this.showCustomHtmlBlock1) {
						return null
					}

					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl + "winners_html_block_top.html")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showCustomHtmlBlock1 = false
								reject(error.message);
							});
					});
				},
			},
			customHtmlBlock2:        {
				get() {
					if (!this.showCustomHtmlBlock2) {
						return null
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl + "winners_html_block_bottom.html")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showCustomHtmlBlock2 = false
								reject(error.message);
							});
					});
				},
			},

		},
		computed:      {
            staticBaseUrl() {
                let origin = this.$config.serverUrl + '/../static/files/';
                if (this.$config.env === 'local') {
                    origin = location.origin + '/static/files/';
                }
                return origin;
            },
			user: get("auth/user"),

			userType() {
				let userType = this.$store.get('auth/currentUserType') || '';
				return userType.toLowerCase();
			},
			filteredChecks() {
				if (!this.winners) {
					return null;
				}
				return this.winners.filter(check => check.type === this.userType);
			}
		}
	};
</script>
<style lang="scss" scoped>
.match3-winners {
    .prize-card {
        box-shadow: 0 0 8px #dee2e6;
        .prize-winners {
            line-height: 1.2;
        }
    }
}
.winners {
    .winner:not(:last-child) {
        border-bottom: 2px solid $lightgray;
    }
    ::v-deep .nav-tabs {
        border: 0;
        .nav-item {
            width: 100%;
            text-align: center;
            border-bottom: 10px solid $light;
            @media (min-width: $md) {
                flex: 1 1 0px;
                border-bottom: 0;
                margin-bottom: 1px;
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
            @media (min-width: $md) {
                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }
        .nav-link {
            background-color: $green;
            color: $light;
            border: 0;
            border-radius: 0;
            text-decoration: none;
            padding: 10px;
            @media (min-width: $lg) {
                font-size: 23px;
            }
            &.active {
                background-color: $light;
                color: $green;
            }
        }
    }
}
</style>
