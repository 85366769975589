<template>
	<div class="p-3">
        <p>There was a problem accessing the server. Please ensure you have a good connection and try again.</p>
        <button type="button" class="btn btn-sm btn-primary btn-red" @click="doAction" v-ada>Reload</button>
    </div>
</template>

<script>
	import {setStorage} from '@/utils/storage'

	export default {
		name: 'offlineMessage',

		props: {
			action: Function,
			show: Boolean
		},

		computed: {
			visible() {
				// Doing it this way instead of "return a||b" to avoid OR optimization
				let args = [
					this.$store.get('global/offline'),
					this.show
				];

				return args.reduce((a, b) => {
					return a || b;
				});
			}
		},

		mounted() {
			this.unwatch = this.$watch('visible', (to) => {
				$(this.$el).css('display', to ? 'block' : 'none');
			}, {immediate: true});
		},

		beforeDestroy() {
			this.unwatch();
		},

		methods: {
			doAction() {
				if (this.action) {
					this.action();
				}
				else {
					setStorage('temp_auth', this.$store.get('auth/isLoggedIn'), () => {
						window.location.reload();
					});
				}
			}
		}
	}
</script>
