<template>
    <div class="container-fluid h-100">
        <div class="row mb-100 p-5 text-center container mx-auto bg-cta color-black font-weight-bold align-items-center">
            <div class="col-sm-12 col-md-4 mx-auto">
                <div class="font-size-xl roboto-900-italic">SIGN UP</div>
                <p>If this is your first visit, <router-link :to="{name:'new-player'}">SIGN-UP</router-link> and create a new MONOPOLY Savings Road Trip game account.</p>
                <p><router-link :to="{ name:'new-player' }"><img src="../assets/images/arrow-circle-right-solid.png" class="img-fluid" alt="SIGN UP"><span class="sr-only sr-only-focusable">SIGN UP</span></router-link></p>
            </div>
            <div class="col-sm-12 col-md-4 mx-auto">
                <div class="font-size-xl roboto-900-italic">LOG IN</div>
                <p class="mb-075">If you have already signed-up, <router-link :to="{ name: 'returning-player' }">LOG-IN</router-link> to enter your Game Codes to play the MONOPOLY Match 3 Game for your chance to win up to $100,000.</p>
                <p><router-link :to="{ name: 'returning-player' }"><img src="../assets/images/arrow-circle-right-solid.png" class="img-fluid" alt="LOG IN"><span class="sr-only sr-only-focusable">LOG IN</span></router-link></p>
            </div>
            <div class="col-sm-12 col-md-4 mx-auto">
                <div class="font-size-xl roboto-900-italic">HOW TO PLAY</div>
                <p>Find out <router-link :to="{ name: 'how-to-play' }">HOW TO PLAY</router-link> MONOPOLY Savings Road Trip with over $30,000,000 in Prizes and money-saving offers.</p>
                <p><router-link :to="{ name: 'how-to-play' }"><img src="../assets/images/arrow-circle-right-solid.png" class="img-fluid" alt="HOW TO PLAY"><span class="sr-only sr-only-focusable">HOW TO PLAY</span></router-link></p>
            </div>
        </div>
        <div class="position-absolute mx-n1">
            <tracker v-if="$config.useTrackers" name="home"></tracker>
        </div>
    </div>
</template>

<script>
import tracker from "@/components/trackers/General";

export default {
    name:       "home",
    components: {tracker},
    data() {
        return {
            watchConnectivityState: false,
            classname:              "I am a name to get classified",
        };
    },
    computed: {


        isApp() {
            return process.appVersion;
        },

        loginUrl(){
            return this.$config.hostStoreUrl + '/Card_mod/Card_Login_Email_S.las?fFWD_URL=/Monopoly/Play/';
        },

        registerUrl(){
            // following signup, we get transferred to the /Play/ page, which should then redirect us back to the website
            // inside the mobile app, we will detect that the inAppBrowser was directed to the website, strip the token out of the URL,
            // and use it to authenticate the current session
            // return this.$config.hostStoreUrl +  '/card_mod/Card_Login_Stub.las?-token.signup=Reg&fFWD_URL=/Monopoly/Play/';
            return this.$config.hostRegistrationUrl;
        }

    },
    methods:  {
        register() {
            // this may be completely unnecessary, but it existed in SPW code
            this.$EventBus.$when("deviceready", this.beginRegister);
        },
        beginLoginOrRegister(externalSiteUrl) {
            let ref = cordova.InAppBrowser.open(
                externalSiteUrl,
                "_blank",
                "location=no,hardwareback=no,zoom=no"
            );

            let                    returnedToSPW   = false;

            ref.addEventListener('exit', function () {

            });

            // Every time a new page loads, I want to know about it
            let handler = (e) => {
                if (returnedToSPW) {
                    return;
                }

                let domain = e.url.match(/^https?:\/\/([^\/?]+)(?:.*)?$/);
                if (domain) {
                    domain = domain[1];
                } else {
                    domain = '';
                }

                if (/savingsroadtrip(?:codes)?\.com$|^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::8081)?$/.test(domain)) {
                    returnedToSPW = true;
                    ref.close();
                    // extract the token from the URL we were returned to,
                    // remember it, and re-initialize the authentication
                    let parsedUrl = new URL(e.url);
                    let eToken = parsedUrl.searchParams.get('sessionid');
                    this.$log.info('Return URL =', parsedUrl.href);
                    this.$log.info('eToken =', eToken );
                    // prefixing the token with * informs the server this is a temporary token
                    this.$store.set('auth/token', '*'+eToken);
                    this.$store.dispatch('auth/refreshUser').then(() => {
                        if (this.$store.get('auth/isLoggedIn')) {
                            this.$router.replace({name: 'enter-code'});
                        } else {
                            // todo something went wrong. we should report this error back to the server
                            this.$router.replace({name: 'returning-player'});
                            // this.$modalService.create('errorLoggingIn');
                        }
                    });
                }
            };

            ref.addEventListener("loadstart", handler);
            // ref.addEventListener("loadstop", handler);
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
