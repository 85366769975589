<template>
    <div class="select-cards">
        <section class="page-title show-this py-3 text-center container-fluid">
            
            <!--            <p class="font-normal mb-1" v-if="chosenCards.length">Your selected numbers: {{ chosenCards.join() }}</p>-->
        </section>
        <div class="content-area container-fluid">
            <div class="row mb-5 justify-content-center">
                <div class="col-11 py-3 py-lg-5 mt-5">
                    <div v-if="action !== 'Choose 3'">
                        <h1 class="text-center font-size-largest font-weight-normal mt-lg-4 text-appear text-shadow-0-4-2 color-light" :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-html="pageHeadline" v-if="action === 'Choose 3'"></h1>
                        <h2 class="text-center font-size-billboard font-weight-normal text-appear text-shadow-0-4-2 color-light"  :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-if="action === 'Choose 3'" v-html="prizeCategory"></h2>
                        <p class="font-size-xl font-special text-center mb-0 text-appear text-shadow-0-4-2 color-light" :class="{ 'font-size-billboard' : reveal,'blink': textAppearTransition,'show-this': showThisText }">{{ instruction }}</p>
                        <p class="font-size-xl font-special text-appear text-center color-light"  :class="{ 'blink': textAppearTransition }">{{ reason }}</p>
                        <p class="font-special font-size-largest mb-0 text-center color-light" v-if="action !== 'Choose 3'">You selected numbers: {{ chosenCards.join() }}</p>
                    </div>
                    <div v-else>
                        <h1 class="text-center text-shadow-0-4-2 font-size-xxl page-title font-weight-normal mt-lg-4 color-light" :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-html="pageHeadline" v-if="action === 'Choose 3'"></h1>
                        <h2 class="text-center text-shadow-0-4-2 font-size-billboard page-prize font-weight-normal color-light"  :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-if="action === 'Choose 3'" v-html="prizeCategory"></h2>
                        <p class="font-size-largest text-center font-special color-light page-ins mb-0" :class="{ 'font-size-billboard' : reveal,'blink': textAppearTransition,'show-this': showThisText }">{{ instruction }}</p>
                    </div>
                    <template v-if="action === 'Choose 3'">
                        <div class="ball-selection px-1 px-lg-5 le-balls justify-content-center" :class="{'show-this': showThisText}"  @transitionend="showBallPickItems">
                            <div class="text-center ball-pick-item" :class="{'show-this': visibleBalls >= index}"  v-for="index in 20" :key="index">
                                <input type="checkbox"
                                       :id="`ball-${index}`"
                                       name="pick[]"
                                       :value="index"
                                       class="d-none ball-pick"
                                       v-model="chosenCards"
                                >
                                <label :for="`ball-${index}`" class="ball-number small-ball roboto-900-italic">
                                    <span class="number" :class="{checked: chosenCards.indexOf(index)>=0}" @keyup.enter="checkMe" @keyup.space="checkMe" tabindex="0">{{ index }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <submit-button class="btn btn-green color-light text-shadow-1-1-2 roboto-900 rounded ticket my-4"
                                           :disabled="chosenCards.length<3"
                                           :loading="busy"
                                           @click="submit3"
                                           v-if="action==='Choose 3' && !nextAction">Submit Your Picks
                                           <span class="sr-only" v-if="chosenCards.length<3">by selecting 3 numbers out of 20 above</span>
                                           <span class="sr-only" v-if="chosenCards.length==3">that you selected</span>
                            </submit-button>
                        </div>
                    </template>

                    <div class="prize-info" v-if="action==='Reveal Prize'">
                        <main class="content-area container-fluid">
                            <div class="row">
                                <div class="col">
                                    <p class="font-special text-center font-size-largest mb-0 color-light">The winning numbers</p>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-3 ball-cont" v-for="(chosenCard, index) in winningCards" :key="chosenCard">
                                    <div class="text-center">
                                        <div class="ball-number big-ball roboto-900-italic" :class="{ 'ball-in' : winningBallTransition }" @transitionend="showNextSteps(index)">
                                            <span class="number">{{ chosenCard }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <figure class="ball-machine"  :class="{ 'machine-in' : machineTransition }" @transitionend = "showBalls">
                                        <div class="balls">
                                            <div class="ball-number tiny-ball roboto-900-italic" v-for="(ball, bc) in ballsInMachine" :key="bc">
                                                <span class="number">{{ ball }}</span>
                                            </div>
                                        </div>
                                        <!-- <img class="img-fluid machine-image" src="../assets/images/machine-clear.png" alt="Machine" v-if="!showWinLoseImage"> -->
                                        <div class="col-12 col-md-6 mx-auto text-center px-lg-5 px-2" :class="{ 'steps-in' : stepsTransition }" @transitionend="stepsEnd" v-if="showWinLoseImage">
                                            <router-link class="d-block btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                                            <router-link class="d-block btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'code-history'}">View Code History</router-link>
                                        </div>
                                        <img class="img-fluid winner" src="../assets/images/winner.png" alt="Winner" v-if="showWinLoseImage">
                                    </figure>
                                </div>
                            </div>
                        </main> 
                        <!--
                        <div class="fake-modal next-steps bg-darkgreen text-center border-2 bordered-2 p-lg-5 p-3" :class="{ 'steps-in' : stepsTransition }" @transitionend="stepsEnd">
                            <p class="font-size-largest text-center font-special">{{ modalText }}</p>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'code-history'}">View Code History</router-link>
                        </div>
                        -->
                    </div>
                    <div class="prize-info" v-if="action === 'Non-Winner'">
                        <main class="content-area container-fluid">
                            <div class="row">
                                <div class="col">
                                    <p class="font-special text-center font-size-largest mb-0 color-light">The winning numbers</p>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-3 ball-cont" v-for="(chosenCard, index) in winningCards" :key="chosenCard">
                                    <div class="text-center">
                                        <div class="ball-number big-ball roboto-900-italic" :class="{ 'ball-in' : winningBallTransition }" @transitionend="showNextSteps(index)">
                                            <span class="number">{{ chosenCard }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <figure class="ball-machine"  :class="{ 'machine-in' : machineTransition }" @transitionend = "showBalls">
                                        <div class="balls">
                                            <div class="ball-number tiny-ball roboto-900-italic" v-for="(ball, bc) in ballsInMachine" :key="bc">
                                                <span class="number">{{ ball }}</span>
                                            </div>
                                        </div>
                                        <!-- <img class="img-fluid machine-image" src="../assets/images/machine-clear.png" alt="Machine" v-if="!showWinLoseImage"> -->
                                        <div class="col-12 col-md-6 mx-auto text-center px-lg-5 px-2" :class="{ 'steps-in' : stepsTransition }" @transitionend="stepsEnd" v-if="showWinLoseImage">
                                            <router-link class="d-block btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                                            <router-link class="d-block btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'code-history'}">View Code History</router-link>
                                        </div>
                                        <!-- <img class="img-fluid non-winner" src="../assets/images/non-winner.png" alt="Non Winner" v-if="showWinLoseImage"> -->
                                    </figure>
                                </div>
                            </div>
                        </main>
                        <!--
                        <div class="fake-modal next-steps bg-darkgreen text-center border-2 bordered-2 p-lg-5 p-3" :class="{ 'steps-in' : stepsTransition }" @transitionend="stepsEnd">
                            <p class="font-size-largest text-center font-special">{{ modalText }}</p>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'code-history'}">View Code History</router-link>
                        </div>
                        -->
                    </div>
                    <b-modal id="next-steps"
                             no-close-on-backdrop
                             content-class="border-2"
                             v-model="modalShow"
                             centered
                             size="lg"
                             tabindex="-1"
                             role="dialog"
                             hide-footer
                             hide-header>
                        <div class="text-center">
                            <p class="font-size-banner font-special mb-0">{{ modalDesignation }}</p>
                            <h2 class="font-size-largest font-special mb-5">{{ modalCongratsText }}</h2>
                            <h3 class="font-body font-size-default text-uppercase font-weight-normal" v-if="modalInstructions">{{ modalInstructions }}</h3>
                            <p>{{ modalText }}</p>
                            <router-link class="btn ticket my-4 btn-block btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                            <router-link class="btn ticket my-4 btn-block btn-green color-light text-shadow-1-1-2 rounded" :to="{name:'code-history'}">View Code History</router-link>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.text-shadow-0-4-2 {
    text-shadow: 0px 4px 2px $dark;
}

.text-appear{
    opacity: 1;
    transition: opacity 0.5s ease-out;
    &.blink{
        opacity: 0;
    }
}
.le-balls,
.page-title,
.page-prize,
.page-ins,
.ball-pick-item
{
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &.show-this{
        opacity: 1;
    }
}

.ball-pick-item {
    transition-delay: 0s;
    transition-duration: .02s;
}

.page-prize{
    transition-delay: 1s;
}
.page-ins{
    transition-delay: 1.6s;
}
.le-balls{
    transition-delay: 2s;
}
.prize-info{
    position: relative;
    main.content-area{
		padding-top: 0;
	}
}
.next-steps{
    position: absolute;
    top: 110%;
    opacity: 0;
    transform: translate(0,50%);
    transition: all 0.5s ease-out;
    &.steps-in{
        opacity: 1;
        transform: translate(0,0);
    }
}
.ball-cont{
    .big-ball{
        opacity: 0;
        transform: translate(0, 50%);
        transition: all 0.5s ease-out;
    }
    &:nth-child(1){
        .big-ball{
            &.ball-in{
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    &:nth-child(2){
        .big-ball{
            transition-delay: 1s;
            &.ball-in{
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    &:nth-child(3){
        .big-ball{
            transition-delay: 2s;
            &.ball-in{
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}
.ball-pick-confimation,
.ball-selection {
    display: grid;
    grid-template-columns: repeat(5, 14%);
    grid-row-gap: 0;
    // @media (min-width: $lg){
    //     grid-row-gap: 2rem;
    // }
    @media (max-width: $md - 1){
        grid-template-columns: repeat(5, 20%);
    }

}

.ball-pick-confimation {
    grid-template-columns: repeat(3, 33.33%);
}

.ball-selected {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 404px;
    position: absolute;
    width: 43.9%;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    @media (min-width: $collapse) {
        width: 100%;
    }
}

.ball-number {
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;

    .number {
        display: inline-block;
        height: 60px;
        width: 50px;
        padding-top: 15px;
        padding-right: 10px;
        // padding: 15px 45px;
        color: $light;
        text-shadow: rgb(52, 58, 64) 3px 0px 0px, rgb(52, 58, 64) 2.83487px 0.981584px 0px, rgb(52, 58, 64) 2.35766px 1.85511px 0px, rgb(52, 58, 64) 1.62091px 2.52441px 0px, rgb(52, 58, 64) 0.705713px 2.91581px 0px, rgb(52, 58, 64) -0.287171px 2.98622px 0px, rgb(52, 58, 64) -1.24844px 2.72789px 0px, rgb(52, 58, 64) -2.07227px 2.16926px 0px, rgb(52, 58, 64) -2.66798px 1.37182px 0px, rgb(52, 58, 64) -2.96998px 0.42336px 0px, rgb(52, 58, 64) -2.94502px -0.571704px 0px, rgb(52, 58, 64) -2.59586px -1.50383px 0px, rgb(52, 58, 64) -1.96093px -2.27041px 0px, rgb(52, 58, 64) -1.11013px -2.78704px 0px, rgb(52, 58, 64) -0.137119px -2.99686px 0px, rgb(52, 58, 64) 0.850987px -2.87677px 0px, rgb(52, 58, 64) 1.74541px -2.43999px 0px, rgb(52, 58, 64) 2.44769px -1.73459px 0px, rgb(52, 58, 64) 2.88051px -0.838247px 0px;
        background: url(../assets/images/ball-dark.png) no-repeat;
        background-size: contain;
        background-position: center center;
        font-family: $fontSpecial;
        @include rfs(35px);
        //transition: all 0.3s ease-in-out;
        @media (min-width: $md){
            height: 120px;
            width: 120px;
            padding-top: 30px;
            font-size: 48px;
        }
        @media (max-width: $md - 1){
            font-size: 20px;
            padding-right: 4px;
        }
        &:hover,
        &:focus {
            color: $red;
            text-shadow: none;
            background: url(../assets/images/ball.png) no-repeat;
            // background-size: auto 100%;
            background-size: contain;
            background-position: center center;
            // color: $green;
        }
    }

    &.big-ball {
        padding: 0;
        cursor: auto;

        .number {
            // @include rfs(87px);
            // width: 100%;
            font-size: 48px;
            height: 100px;
            width: 100px;
            color: $red;
            text-shadow: none;
            background: url(../assets/images/ball.png) no-repeat;
            background-size: auto 100%;
            background-position: center center;
            @media (min-width: $md){
                height: 140px;
                width: 140px;
            }
            
            @media (min-width: $lg){
                height: 200px;
                width: 200px;
                font-size: 84px;
            }
            @media (min-width: $xxl){
                height: 220px;
                width: 220px;
                padding-top: 55px;
                font-size: 72px;
            }
        }

        &:hover,
        &:focus {
            color: #000;
        }
    }

    &.tiny-ball {
        .number {
            font-size: 14px;
            // padding: 12px;
            height: 40px;
            width: 40px;
            @media (min-width: $md){
                height: 80px;
                width: 80px;
                padding-top: 15px;
                font-size: 35px
            }
            @media (min-width: $collapse) {
                @include rfs(35px);
                // padding: 15px 45px;
            }
        }
    }
}
.ball-pick-item .number.checked {
    color: $red;
    text-shadow: none;
    background: url(../assets/images/ball.png) no-repeat;
    // background-size: auto 100%;
    background-size: contain;
    background-position: center center;
}
.winner, .non-winner {
    transition: all 0.5s ease-in;
    opacity: 1;
}
.ball-machine {
    position: relative;
    bottom: -32px;
    @media (min-width: $lg) {
        bottom: -64px;
    }
    width: 100%;
    text-align: center;
    transition: all 0.5s ease-out;
    opacity: 0;
    transform: translate(0, 50%);
    &.machine-in{
        opacity: 1;
        transform: translate(0, 0);
    }
    img {
        max-width: 100%;
    }
}
.balls{
    position: absolute;
    height: 50%;
    width: 50%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
}
.tiny-ball{
    position: absolute;
    bottom: 0;
    animation: bounceSm 1s linear infinite;
    left: 0;
    
    &:nth-child(1){
        left: 75%;
        animation: bounceSm 1s linear infinite;
    }
    &:nth-child(2){
        left: 0;
        animation: bounceMd 1s linear infinite;
    }
    &:nth-child(3){
        left: 12%;
        animation: bounceMd 1s linear infinite;
    }
    &:nth-child(4){
        left: 35%;
        animation: bounceSm 1s linear infinite;
    }
    &:nth-child(5){
        left: 55%;
        animation: bounceLg 1s linear infinite;
    }
    &:nth-child(6){
        left: 65%;
        animation: bounceMd 1s linear infinite;
    }
    &:nth-child(7){
        left: 32%;
        animation: bounceLg 1s linear infinite;
    }
    &:nth-child(8){
        left: 60%;
        animation: bounceLg 1s linear infinite;
    }
    &:nth-child(9){
        left: 25%;
        animation: bounceSm 1s linear infinite;
    }
    &:nth-child(10){
        left: 40%;
        animation: bounceLg 1s linear infinite;
    }

}
@keyframes bounceMd {
  0% {
        opacity: 0;
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(75%, -75%) rotate(180deg);
    }
    100%{
        opacity: 0;
        transform: translate(100%, 0) rotate(360deg);
    }
}
@keyframes bounceSm{
    0% {
        opacity: 0;
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(-25%, -155%) rotate(180deg);
    }
    100%{
        opacity: 0;
        transform: translate(-100%, 0) rotate(360deg);
    }
}
@keyframes bounceLg{
    0% {
        opacity: 0;
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(0, -200%) rotate(180deg);
    }
    100%{
        opacity: 0;
        transform: translate(0, 0) rotate(360deg);
    }
}
</style>
<script>
import {get} from 'lodash';
import vuexStore from '../store'

export default {
    computed: {

        pageHeadline() {
            if (this.playingForPrizeName) {
                // return this.title + ' ' + this.prizeNameWithDollarSign;
                return this.title;
            }
            return null
        },
        playingForPrizeName() {
            return get(this.$store.get('auth/match3Game'), 'prize_name');
        },

        isDev() {
            return this.$config.env === 'local'
        },

        prizeNameWithDollarSign() {
            if (this.prizeCategory.substr(0, 1) === '$') {
                return '<sup class="currency">$</sup>' + this.prizeCategory.substr(1);
            }
            return this.prizeCategory;
        }

    },
    data() {
        return {
            busy:                   false,
            instruction:            'Pick 3 numbers out of 20 below',
            title:                  'You are playing for',
            chosenCards:            [],
            winningCards:           [],
            revealCards:            false,
            cards:                  {},
            action:                 'Choose 3',
            prizeCategory:          '', // I am not quite sure but there might be multiple categories or level that the user is playing, might be playing for 1,000, 10,000, or 1,000,000.
            prize:                  null,
            nextAction:             null,
            chosenConsolationPrize: null,
            modalShow:              false,
            modalDesignation:       '',
            modalCongratsText:      '',
            modalInstructions:      '',
            modalText:              '',
            hTimer:                 null,
            reveal:                 false,
            reason:                 '',
            // For transitions
            showThisText:           false,
            winningBallTransition : false,
            machineTransition:      false,
            machineHideTotally:     false,
            stepsTransition:        false,
            textAppearTransition:   false,
            showWinLoseImage:       false,

            // Machine Balls
            //ballsInMachine:         [1,2,3,4,5,6,7,8,9,15,17,20,21,25],
            ballsInMachine:         [],

            nextBallToShow:0,
            visibleBalls: 0
        }
    },
    /**
     * Prevent entry to game board unless a match-3 game has been started
     * @param to
     * @param from
     * @param next
     */
    beforeRouteEnter(to, from, next) {
        let playing = vuexStore.get("auth/user@playMatch3Game");
        if (!playing) {
            next({name: 'enter-code'});
            return;
        }

        next();
    },
    created() {
        let game = this.$store.get("auth/user@playMatch3Game");
        if (game) {
            this.action = game.action;
            this.prizeCategory = game.prize_name;
        }

    },

    methods: {

        checkMe() {
            event.target.click();
        },

        // submit chosen 3 cards
        submit3() {
            this.busy = true;
            this.showThisText = false;
            this.$http.post(`/game/submitMatch3`, {chosenCards: this.chosenCards}).then(response => {
                let data = response.data;
                if (data) {
                    this.chosenCards = this.chosenCards.sort();
                    // Hide the text first
                    this.textAppearTransition = true;
                    this.action = data.action;
                    this.reveal = true;
                    this.prize = data.prize;
                    this.reason = data.contentText;
                    this.showThisText = true;
                    // let delay = 3000;
                    let delay = 20000;
                    // setTimeout(() => {
                    //    this.winningBallTransition = true;     
                    // }, 1000);
                    setTimeout(() => {
                        this.scrollTop();
                        // Show the machine
                        this.machineTransition = true;
                        // Handles the text in the page header
                        this.title = data.title;
                        if (data.action === 'Non-Winner') {
                            this.instruction = data.instructions;
                            this.winningCards = data.winning_cards.sort();
                            // Not a match at all
                            if (!data.congratsText && !data.contentText) {
                               this.instruction = 'Sorry, not a match'; 
                               //this.reason = 'Sorry. Thanks for Playing!'; 
                               this.reason = ''; 
                            }
                            // Likely partial matching with sweepstakes entry will not have instructions
                            if (!this.instruction) {
                                this.instruction = 'Thank you for playing';
                            }
                        } else {
                            // This has to be pulled
                            this.prize = data.name
                            this.instruction = 'You won ' + data.prize.title;
                            this.winningCards = this.chosenCards;
                        }
                    }, 1000);

                    // const machine = document.querySelector( '.ball-machine' );
                    // console.log( machine );
                    // machine.ontransitionend = () => {
                    //     this.winningBallTransition = true;
                    // };
                    //this.setModalText(data);
                    // setTimeout(() => {
                        // this.setModalText(data);
                        // this.modalShow = true;
                    // }, delay);
                    return;
                } else {
                    // error in response
                }
            }).catch(error => {
                console.error(error);
                // who knows what to do here
                this.$modalService.create("codeEntry/generic",
                    {
                        title:      'Sorry...',
                        message:    'We are unable to process your request at this time.',
                        isWinner:   false,
                        showCloseX: false
                    })
                    .on('close', () => {
                        this.$router.push({name: 'enter-code'})
                    })
            }).finally(() => {
                this.busy = false;
            })

        },
        setModalText(responseFromServer) {
            this.modalDesignation = responseFromServer.designation;
            this.modalCongratsText = responseFromServer.congratsText;
            this.modalInstructions = responseFromServer.instructions;
            this.modalText = responseFromServer.contentText;
        },
        stepsEnd(){
            this.machineHideTotally = true;
        },
        showNextSteps( count ){
            if( count === 2 ){
                this.textAppearTransition = false;
                //this.machineTransition = false;
                this.showWinLoseImage = true;
                this.stepsTransition = true;
                // this.modalShow = true;
                //setTimeout(() => {
                //    this.scrollDown();
                //}, 200);
                setTimeout(() => {
                    this.scrollTop();
                }, 2000);
            }
        },
        showBalls(){
            if( this.machineTransition == true ){
                this.winningBallTransition = true;
                // this.textAppearTransition = false;
            }
        },
        showThese(){
            setTimeout(() => {
                this.showThisText = true;
            }, 200);
        },
        /**
         * transitionend event propogates to parents
         * @param x
         */
        showBallPickItems(x) {
            this.visibleBalls++;
        },
        scrollTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        scrollDown() {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        },
    },
    mounted() {
        if (this.isDev) {
            window.advance = () => {
                this.action = this.nextAction;
                this.nextAction = '';
            }
        }
        this.showThese(); 
    },
    watch: {
        chosenCards(to, from) {
            if (to.length > 3) {
                this.chosenCards = from.slice(0, 3);
            }
        }
    },
}
</script>
