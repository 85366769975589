<template>
    <div class="forgot-password">
		<div class="container">
            <div class="bg-white d-flex flex-column mx-auto my-5 rounded-8 p-4 p-xl-5 box-shadow-0-0-10">
                <div class="font-weight-bold pb-3 font-size-large">Forgot Password</div>
				<form method="post" action="" @submit.prevent="submit" v-if="!emailSent">
					<b-form-group id="email" label="Enter the email associated with your account" label-for="email-input" aria-describedby="email-feedback">
						<b-form-input v-model="form.email"
									  id="email-input"
									  type="email"
									  ref="email"
									  :state="isError(error.errorMessage)"
									  autocomplete="new_email"></b-form-input>
						<b-form-invalid-feedback id="email-feedback" v-html="error.errorMessage"></b-form-invalid-feedback>
					</b-form-group>

					<recaptcha3 v-if="$config.useCaptcha" :siteKey="$config.recaptcha.siteKey" ref="captchaComponent" context="password_reset"></recaptcha3>

					<submit-button :loading="busy" class="btn btn-lightgreen btn-rounded color-light px-5 mt-4">Reset Password</submit-button>
					
					<p class="my-3 small text-dark">
						Please add {{ gamesupport }} to your email whitelist. If you do not receive an email, please
						<router-link to="/contact" class="text-dark">contact us</router-link>
					</p>

				</form>
                <!-- EMAIL SENT -->
                <div v-else>
                    <p>Please check your email. Instructions for resetting your password have been sent to you.</p>
                    <p class="my-3 small">
                        Please add gamesupport@savingsroadtripcodes.com to your email whitelist. If you do not receive an email, please
                        <router-link to="/contact" class="text-dark">contact us</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {get} from 'lodash';
import Recaptcha3 from '../components/Recaptcha3';
export default {
    name: "forgot-password",
    components:{Recaptcha3},
    data() {
        return {
            busy:      false,
            form:      {
                email:   '',
                captcha: '',
            },
            error:     {
                errorMessage: null,
                email:        null
            },
            emailSent: false
        }
    },
    methods: {
        async submit() {
            this.busy = true;
            this.form.captcha = await this.$refs.captchaComponent.getToken();
            this.$http.post('/user/requestPasswordReset', this.form)
                .then(this.emailSentSuccess, this.emailSentFailure)
        },
        emailSentSuccess(response) {
            this.emailSent = true;
            this.busy = false;
            console.log('success')
        },
        emailSentFailure(xhr) {
            this.busy = false;
            let error = get(xhr, 'response.data.error', null);
            if (error) {
                console.error(error);
                this.error = error;
                this.$refs.email.focus();
            } else {
                this.error.errorMessage = 'There was an error processing your request.';
                this.error.email = null;
            }
        },
        isError(value) {
            if (typeof value === 'undefined' || value === null) {
                return null;
            }
            return !value;
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
