<template>
    <div class="container-fluid contact">
        <div class="row page-title">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <h1 class="text-center py-0 py-md-2 m-0 roboto-900">Contact Us</h1>
            </div>
        </div>

        <div class="container">
            <div class="bg-white p-3 p-lg-5 my-5 mx-0 mx-lg-3 rounded-10">
                <multi-step ref="contactSteps" :startingStep="$store.get('auth/user') ? 'logged_in' : 'not_logged_in'" class="container">

                <!--
                ------------ First Step for logged in and not logged in
                -->
                <div slot="logged_in" class="no-back-button">
                    <p>Hello, you are logged in as
                        <span class="badge bg-red text-white">{{$store.get('auth/currentUserEmail')}}</span>, is this the correct email address?<br>
                        If not, we cannot change your email address, please logout and create a new account and agree to the rules with your new email address.
                    </p>
                    <p>
                        Your account type is set to <span class="badge bg-red text-white">{{$store.get('auth/currentUserType')}}</span>. If this is not correct <a key="logged_in-1" @click="changeStep('customer_associate_codes')" href="javascript:void(0)" class="color-blue">click here</a>.
                    </p>
                    <p>
                        If everything above is correct, please <a key="logged_in-2" @click="changeStep('email_validated')" href="javascript:void(0)" class="color-blue">proceed</a>.
                    </p>
                </div>
                <div slot="not_logged_in" class="no-back-button">
                    <form @submit.prevent="checkEmail">
                        <div class="alert alert-danger text-center" v-if="error">{{error}}</div>
                        <div class="form-group">
                                <p>Thanks for playing! If you have any questions about playing the game or experience any difficulties playing the online game, first please visit the
                                    <router-link :to="{name: 'faq'}" class="color-blue">Frequently Asked Questions</router-link>
                                    page. Most questions can be quickly answered by visiting this page.
                                </p>
                                <label for="user_email">However, if you are still having trouble start by entering your email address below:</label>
                                <input type="email" v-model="form.email" placeholder="email address" id="user_email" class="form-control">
                                <div class="form-error" v-if="errors.email">{{errors.email}}</div>
                            </div>
                            <button type="submit" class="btn btn-lightgreen btn-rounded color-light roboto-600 px-5 mt-4 d-inline-block" :disabled="busy" v-ada>Submit</button>
                        </form>
                    </div>

                    <!--
                    ------------ Not logged in, ask to verify email
                    -->
                    <div slot="has_account">
                        <p>Is <span class="badge bg-red text-white">{{form.email}}</span> the correct email address?</p>
                        If yes, <a key="has_account-1" @click="changeStep('email_validated')" href="javascript:void(0);" class="color-blue">proceed</a>.<br>
                        If no, we cannot change your email address, please
                        <router-link :to="{name: 'new-player'}" class="color-blue">create a new account</router-link>
                        and agree to the rules with your new email address.
                    </div>

                    <!--
                    ------------ No account, ask them to register
                    -->
                    <div slot="has_no_account">
                        <p>We have reviewed the database, and this email is not in the system. Before you can enter your online game codes, you need to
                            <router-link :to="{name: 'new-player'}" class="color-blue">register</router-link>
                            for the game as a new player. Please
                            <router-link :to="{name: 'new-player'}" class="color-blue">register</router-link>
                            and begin playing. Thank you.
                        </p>

                        <!-- <div>
                            More Help:
                            <ol class="more-help">
                                <li key="has_no_account-1" @click="changeStep('cant_register')"><span>Cannot register</span></li>
                                <li key="has_no_account-4" @click="changeStep('contact_form')"><span>None of these</span></li>
                            </ol>
                        </div> -->
                    </div>

                    <!--
                    ------------ Can't Register!
                    -->
                    <div slot="cant_register" class="add-form-btn">
                        <h2 class="text-dark">Cannot register</h2>
                        <p>Are you attempting to register using the app, or via the website? If you are using the app, please try using the website. If you are using the website, please be sure you internet browser is up to date. There are links to download the latest versions of several popular browsers in the
                            <router-link :to="{name: 'faq'}">FAQ's</router-link>
                            .
                        </p>
                    </div>

                    <!--
                    ------------ Has an account, ask the questions
                    -->
                    <div slot="email_validated">
                        <button key="email_validated-1" type="button" v-if="!$store.get('auth/currentUserEmail')" @click="changeStep('trouble_logging_in')" class="btn-collapse btn btn-red btn-block" v-ada>Having trouble logging in?</button>
                        <button key="email_validated-2" type="button" @click="changeStep('codes_issues')" class="btn-collapse btn btn-red btn-block" v-ada>Having trouble or questions about codes?</button>
                        <button key="email_validated-3" type="button" @click="changeStep('printing_issues')" class="btn-collapse btn btn-red btn-block" v-ada>Having printing issues?</button>
                        <button key="email_validated-4" type="button" @click="changeStep('prize_issues')" class="btn-collapse btn btn-red btn-block" v-ada>Having trouble or questions about prizes?</button>
    <!--						<button key="email_validated-6" type="button" @click="changeStep('mobile_app_help')" class="btn-collapse btn btn-red btn-block" v-ada>Mobile app help</button>-->
    <!--						<button key="email_validated-7" type="button" @click="changeStep('customer_associate_codes')" class="btn-collapse btn btn-red btn-block" v-ada>Cannot enter associate/customer codes</button>-->
                         <button key="email_validated-8" type="button" @click="changeStep('contact_form')" class="btn-collapse btn btn-red btn-block" v-ada>Something else?</button>
                    </div>

                    <!--
                    ------------ Children of EMAIL VALIDATED
                    -->

                    <div slot="trouble_logging_in">
                        <h2 class="text-dark">Trouble Logging In</h2>
                        <p>Please click the "Play The Game" link in the navigation and login to the game using your email address.</p>
                    </div>
                    <div slot="codes_issues">
                        <h2>Having trouble or questions about codes?</h2>
                        <button key="codes_issues-1" type="button" @click="changeStep('duplicate_code')" class="btn-collapse btn btn-red btn-block" v-ada>Duplicate code error</button>
    <!--						<button key="codes_issues-2" type="button" @click="changeStep('find_online_game_code')" class="btn-collapse btn btn-red btn-block" v-ada>How to find online game code</button>-->
                        <button key="codes_issues-3" type="button" @click="changeStep('invalid_code')" class="btn-collapse btn btn-red btn-block" v-ada>Invalid Code Error</button>
                    </div>
                    <div slot="printing_issues">
                        <h2>Having printing issues?</h2>
                        <button key="printing_issues-1" type="button" @click="changeStep('get_adobe')" class="btn-collapse btn btn-red btn-block" v-ada>Nothing downloads or opens (Get Adobe PDF Reader)</button>
                        <button key="printing_issues-2" type="button" @click="changeStep('code_doesnt_print')" class="btn-collapse btn btn-red btn-block" v-ada>Prize Form doesn't print</button>
                        <button key="printing_issues-3" type="button" @click="changeStep('no_printer')" class="btn-collapse btn btn-red btn-block" v-ada>I don't have a printer</button>
                    </div>
                    <div slot="prize_issues">
                        <h2>Having trouble or questions about prizes?</h2>
                        <button key="prize_issues-1" type="button" @click="changeStep('no_prize_email')" class="btn-collapse btn btn-red btn-block" v-ada>I didn't get prize email</button>
                        <button key="prize_issues-2" type="button" @click="changeStep('claim_prize')" class="btn-collapse btn btn-red btn-block" v-ada>How to claim prize</button>
                    </div>
                    <div slot="game_board">
                        <h2>Have questions about the game board?</h2>
                        <button key="game_board-1" type="button" @click="changeStep('obtain_game_board')" class="btn-collapse btn btn-red btn-block" v-ada>How to obtain a game board</button>
                        <button key="game_board-2" type="button" @click="changeStep('claim_board_prize')" class="btn-collapse btn btn-red btn-block" v-ada>How to claim a game board prize</button>
                        <button key="game_board-3" type="button" @click="changeStep('glue_markers')" class="btn-collapse btn btn-red btn-block" v-ada>Glue or tape markers</button>
                    </div>
                    <div slot="mobile_app_help">
                        <h2>Mobile app help</h2>
                        <button key="mobile_app_help-1" type="button" @click="changeStep('app_prize_forms')" class="btn-collapse btn btn-red btn-block" v-ada>Prize form using the app</button>
                        <button key="mobile_app_help-2" type="button" @click="changeStep('problems_scanning')" class="btn-collapse btn btn-red btn-block" v-ada>Problems scanning codes</button>
                        <button key="mobile_app_help-3" type="button" @click="changeStep('invalid_code')" class="btn-collapse btn btn-red btn-block btn btn-red btn-block" v-ada>Invalid Code</button>
                    </div>
                    <div slot="customer_associate_codes" class="add-form-btn">
                        <h2 class="text-dark">Cannot enter associate/customer codes</h2>
                        <p>Your status is determined by the first code you enter. If that first code is an Associate code, then your account will be set to Associate. Likewise for Customer codes. If your account has been set incorrectly send us a message and let us know whether you should be a Customer or an Associate and we will change your account for you.</p>
                    </div>

                    <!--
                    ------------ Children of codes_issues
                    -->
                    <div slot="duplicate_code">
                        <h2 class="text-dark">Duplicate code error</h2>
                        <p>If you accidentally double click when you submit your code, the system will enter the code and automatically generate the already entered message you received. Please check your Code History to verify that your code appears there, and to determine if it is a winning code.</p>
                    </div>
                    <div slot="find_online_game_code">
                        <h2 class="text-dark">How to find online game code</h2>
                        <p>Online game codes are contained within the game piece you receive at the store. Not all game pieces will contain an online game code. Your game piece will have either an Instant Winner Coupon, Money Saving Offer, or Online Game Code; along with four (4) game markers which can be used on the game board.</p>
                    </div>
                    <div slot="invalid_code">
                        <h2 class="text-dark">Invalid Code Error</h2>
                        <p>The codes you need to be entering are the 12 digit alpha-numeric codes located within the game piece you receive at the store. There is a sample code printed on the game board for your reference. Please keep in mind that not all game pieces will contain an online game code. If you are still unsure how to locate your online game codes, please ask for assistance at your store's customer service desk.</p>
                    </div>

                    <!--
                    ------------ Children of printing_issues
                    -->
                    <div slot="get_adobe">
                        <h2 class="text-dark">Nothing downloads or opens</h2>
                        <p>In order to view and download the prizes you will need to have Adobe Reader installed. It is a free PDF reader you can download on their website: <a href="https://get.adobe.com/reader/" target="_blank" class="color-blue">https://get.adobe.com/reader/</a>
                        </p>
                    </div>
                    <div slot="code_doesnt_print">
                        <h2 class="text-dark">Prize Form doesn't print</h2>
                        <ul>
                            <li>When the print options page opens up, click on the Advanced button.</li>
                            <li>You will find a checkbox labeled as Print As Image. Click that box.</li>
                            <li>You can also select a print resolution. 300 dpi should be selected.</li>
                            <li>Click OK to close the Advanced window.</li>
                            <li>Click OK in the Print window to print the PDF file.</li>
                        </ul>
                        <!-- <p>If the above doesn't solve the issue, please let us know.</p> -->
                    </div>
                    <div slot="no_printer">
                        <h2 class="text-dark">I don't have a printer</h2>
                        <p>If you don't have a working printer, or a way to print, perhaps a relative or friend will allow you to print your prize claim form using their computer. Another alternative would be to print your prize claim from at your local library or other venue.</p>
                        <p>Prize forms must be printed in order to claim a prize.</p>
                    </div>

                    <!--
                    ------------ Children of prize_issues
                    -->
                    <div slot="no_prize_email">
                        <h2 class="text-dark">I didn't get prize email</h2>
                        <p>If you cannot access your prize claim forms by using the link in the email notification, you can access your prize claim forms through your Code History. While you are logged into your game account on the website, click on "Code History" at the top of the page. Then click "Print" to print your prize claim forms.</p>
                    </div>
                    <div slot="claim_prize">
                        <h2 class="text-dark">How to claim prize</h2>
                        <p>If you have won a prize in the online portion of the game, according to the Official Rules, you will need to print your prize claim form to collect your prize. Once you are logged into your game account on desktop (not the app), click on 'Code History' at the top of the page. This will bring up a list of all valid game codes you have entered. Prizes won will be indicated next to any winning codes. Check the box next to any winning code(s), then click on 'Print Prize Forms' at the bottom of your Code History to print your prize claim forms. Each form will have instructions on how to redeem. Those instructions will vary by prize.</p>
                    </div>

                    <!--
                    ------------ Children of prize_issues
                    -->
                    <div slot="obtain_game_board">
                        <h2 class="text-dark">How to obtain a game board</h2>
                        <p>Game boards are only available at participating stores.</p>
                    </div>
                    <div slot="claim_board_prize">
                        <h2 class="text-dark">How to claim a game board prize</h2>
                        <p>If you have won a prize on the Game Board, please take your game board to your store's customer service desk to receive your prize claim form. Each form will have instructions on how to redeem. Those instructions will vary by prize.</p>
                    </div>
                    <div slot="glue_markers">
                        <h2 class="text-dark">Glue or tape markers</h2>
                        <p>Game Administrators recommend that you do not use glue or tape to attach your markers to the game board. If you should win one of the prizes, you will need to move your markers to a new game board when you submit your prize claim. If you have attached your markers with glue or tape, you may damage the integrity of the game piece when you try to move it to a new game board. We understand that the markers have very little adhesive on them. We have had some customers tell us that they have cut the adhesive section of a Post-It Note as 'tape' to attach their game markers to the board. Alternatively some are using paper clips. </p>
                        <p v-if="$store.get('auth/isLoggedIn')">You can also
                            <router-link :to="{name: 'track-markers'}">track your markers</router-link>
                            online through the website and the app.
                        </p>
                        <p v-else>You can also track your markers online through the website and the app when logged in.</p>
                    </div>

                    <!--
                    ------------ Children of mobile_app_help
                    -->
                    <div slot="app_prize_forms">
                        <h2 class="text-dark">Prize form using the app</h2>
                        <p>Prize forms are generated as a PDF, and cannot be accessed through the app. In order to print your prize claim form, you will need to log into your game account using the live website. Once you have logged into your game account, click on 'Code History' at the top of the page. This will bring up a list of all valid online game codes you have entered. Prizes won will be indicated next to winning codes. Then click on "Print" to print your prize claim forms. Prize claim forms will contain instructions on how to redeem your prize. Those instructions will vary by prize.</p>
                    </div>
                    <div slot="problems_scanning">
                        <h2 class="text-dark">Problems scanning codes</h2>
                        <p>You may be receiving the error message if your scanner cannot read the bar code properly. Please be sure the game piece is on a flat surface, and the room is lit enough so the code is not in shadows. If you are still unable to scan your code, please type it manually.</p>
                    </div>

                    <!--
                    ------------ Final CONTACT FORM
                    -->
                    <div slot="contact_form">
                    <form @submit.prevent="submit">
                    <div class="alert alert-danger text-center" v-if="error">{{error}}</div>

                    <div class="form-group">
                    <label for="email" class="label">Email Address</label>
                    <input id="email" class="form-control" type="text" v-model="form.email" autocorrect="off" autocapitalize="off" spellcheck="false" :readonly="$store.get('auth/isLoggedIn')">
                    <div class="form-error" v-if="errors.email">{{errors.email}}</div>
                    <small v-if="$store.get('auth/isLoggedIn')" id="passwordHelpBlock" class="form-text">
                    If the above email is incorrect you will have to create a new account and agree to the terms under that email address.
                    </small>
                    <div v-if="$store.get('auth/isLoggedIn') && !$store.get('auth/user').receive_emails" class="bg-danger p-1 mt-2">
                    <small>We are unable to send emails to your email address. Either you have blocked us or you inbox is at quota. We may not be able to respond to your request.</small>
                    </div>
                    </div>

                    <div class="form-group">
                    <label for="first_name" class="label">First Name</label>
                    <div class="form-error" v-if="errors.first_name">{{errors.first_name}}</div>
                    <input id="first_name" class="form-control" type="text" v-model="form.first_name">
                    </div>

                    <div class="form-group">
                    <label for="last_name" class="label">Last Name</label>
                    <div class="form-error" v-if="errors.last_name">{{errors.last_name}}</div>
                    <input id="last_name" class="form-control" type="text" v-model="form.last_name">
                    </div>

                    <div class="form-group">
                    <label for="category" class="label">What type of issue are you having?</label>
                    <div class="form-error" v-if="errors.category">{{errors.category}}</div>
                    <select id="category" class="form-control" v-model="form.category">
                    <option value="">Select One...</option>
                    <option v-for="(val, label) in categories" :value="val">{{label}}</option>
                    </select>
                    </div>

                    <div class="form-group">
                    <label for="message" class="label">Message</label>
                    <div class="form-error" v-if="errors.message">{{errors.message}}</div>
                    <textarea id="message" class="form-control text-control" v-model="form.message" rows="3"></textarea>
                    <p class="help-block">(Please be as descriptive as possible.)</p>
                    </div>

                    <button class="btn btn-lightgreen btn-rounded color-light roboto-600 px-5 mt-4 d-inline-block btn-submit" data-style="zoom-out" v-ada :disabled="busy">Submit</button>

                    </form>
                    </div>

                    <div slot="after_contact_form" class="no-back-button">
                        <p>Thanks for contacting game support. We'll get back to you as soon as possible.</p>
                    </div>

                </multi-step>
            </div>
        </div>

    </div>
</template>

<script>
	import * as ladda from 'ladda';

	import {get} from 'lodash';

	import MultiStep from '../components/MultiStep'

	export default {
		name: 'contact',

		components: {
			MultiStep
		},

		computed: {
			steps() {
				return this.$refs.contactSteps;
			}
		},
		data() {
			return {
				submitBtn: null,
busy:false,
				error: '',
				errors: {
					email: '',
					first_name: '',
					last_name: '',
					category: '',
					message: '',
				},
				form: {
					email: this.$store.get('auth/isLoggedIn') ? this.$store.get('auth/currentUserEmail') : '',
					first_name: '',
					last_name: '',
					category: '',
					message: '',
				},

				categories: {
                    Login: "Trouble logging in or registering",
                    Codes: "Code entry problems",
                    Prizes: "Prize or Sweepstakes",
                    // "Account Type": "Change account type (customer <=> associate)",
                    General: "General questions"
				}
			}
		},

		created() {
			this.$on('change', () => {
				let submitBtn = $(':submit');
				this.submitBtn = submitBtn.length ? ladda.create(submitBtn[0]) : false;

				this.errors.email = '';
			});
		},

		mounted() {
            this.watchConnectivity(true);
        },
        beforeDestroy() {
            this.watchConnectivity(false);
        },

        methods: {
            submit() {
                this.busy = true;
                this.$http.post('/support/contact', this.form).then((response) => {
                    this.busy = false;
                    if (response.data && response.data !== true) {
                        // the response is an error message
                        this.error = process.env.VUE_APP_GENERIC_ERROR_MESSAGE;
                        if (process.env.NODE_ENV !== 'production') {
                            this.error += ` (${response.data})`;
                        }
                        window.scrollTo(0, 0);
                    } else {
                        this.changeStep("after_contact_form");
                    }
                }, (error) => {
                    // user error
                    this.busy = false;
                    if (error.response) {
                        let data = error.response.data;
                        let errorData = data.error;
                        this.error = get(errorData, 'errorMessage');
                        $.each(this.form, (field, lastMessage) => {
                            this.errors[field] = null;
                            if (errorData[field]) {
                                this.errors[field] = errorData[field];
                            }
                        });
                    } else {
                        // network error
                        console.error(error.message);
                        this.error = 'Sorry, your request could not be processed at this time. Please try again later.';
                    }
                    $("html, body").animate({scrollTop: "0"});
                });
            },

			checkEmail(e) {
				if (!this.form.email) {
					this.errors.email = 'Email is required';
					$("html, body").animate({scrollTop: "0"});
					return;
				}

				this.busy=true;
				this.$http.post('/support/checkEmail', {email: this.form.email}).then((response) => {
					this.busy=false;
					this.changeStep(response.data ? 'has_account' : 'has_no_account');
				}, (error) => {
					console.error(error.message);
					this.error = error.message
					$("html, body").animate({scrollTop: "0"});
				});
			},

			changeStep(step) {
				this.steps.changeStep(step);
			}
		},
        watch:{
		    $route:{
		        handler(to){
                    let step = to.params.step
                    if (step) {
                        this.$nextTick(() => {
                            console.log('set step to', step);
                            this.changeStep(step);
                            if (step==='contact_form'){
                                this.$nextTick(()=>{

                                    this.form.email='thomas@digitalattic.com';
                                    // this.form.first_name='TestFirst';
                                    // this.form.last_name='TestLast';
                                    // this.form.category='';
                                })
                            }
                        });
                    }
                },
		        immediate:true

            }
        }
	}
</script>

<style lang="scss" scoped>

h2 {
    @include h4;
}
	.more-help {
		span {
			cursor: pointer;
			color: $red;
		}
	}
	.btn {
		text-transform: none;
	}
	.text-control {
		height: $size120;
	}
	.form-control:disabled, .form-control[readonly] {
		background-color: darken($light, 20%);
	}
    .form-error {
        color: $red;
    }
</style>
