<template>
	<modal class="adblock-message">
		<h4 slot="header"></h4>
		<div slot="body">
			<lang id="ad-block-message-content">
				<h4 class="text-center">We have detected that you are using ad blocking technology. Please disable your ad blocker to use our registration page.</h4>
				<hr>
				<p>You can do one of the following:</p>
				<ol>
					<li>Configure your ad blocker not to run on this domain.</li>
					<li>Pause or disable your ad blocker while you register. Afterwards, resume your ad blocker.</li>
					<li>If you prefer not to change your ad blocker settings, click on this <a :href="frameUrl" target="_top">link</a> to complete registration directly. Then return to
						<a href=""></a> to log in and play.
					</li>
				</ol>
				<h4 class="text-center">Thank You!</h4>
			</lang>
		</div>
		<div slot="footer" class="mt-3 btn-block">
			<button class="btn btn-block btn-primary btn-red" @click="$modal.hide()" v-ada>Okay, got it!</button>
		</div>
	</modal>
</template>

<script>
	export default {
		name: 'adBlockMessage',

		props: {
			frameUrl: {
				type: String,
				required: true
			}
		},

		created() {
			this.$modal.on('close', () => {
				this.$router.push({name:'home'});
			});
		}
	}
</script>
