<template>
	<modal v-bind="modalOpts">
        <div slot="header"></div>
		<div slot="body" class="text-center">

            <!-- <img class="col-sm-6 img-fluid mb-4" src="@/assets/images/money-2.png" alt="A winning code was entered" v-if="isWinner !== false"> -->
            <h2 v-html="title" v-if="title"></h2>
            <div class="color-light" v-html="message"></div>
        </div>
		<div slot="footer" class="btn-block">
			<button type="button" class="btn btn-lightgreen color-light text-shadow-1-1-2 rounded btn-block m-1" @click="closeModal" v-ada ref="btnOk">Enter Another Code</button>
			<router-link :to="{name: 'code-history'}" class="btn btn-lightgreen color-light text-shadow-1-1-2 rounded btn-block m-1">Visit Code History</router-link>
		</div>
	</modal>
</template>

<script>
    export default {
        name:     'genericCodeEntryMessage',
        props:    {
			modal: {
				type: Object,
				required: true
			},
            title:   String,
            message: String,
            theme:   String,
            isWinner: Boolean,
            opts:    Object
        },
        computed: {
            modalOpts() {
                let defaults = {
                    classes: ['modal-lg']
                };

                return $.extend(defaults, this.opts || {});
            }
        },
		methods:{
            closeModal() {
                if (this.$route.name !=='enter-code'){
                    this.$router.push({name:'enter-code'});
                    return;
                }
				this.modal.trigger('close');
            }
		},
		mounted(){
        	// unsure this is the correct approach for preventing certain modal elements from receiving focus
        	$(this.$el).find('.modal-close').focus();
		}
    }
</script>

